import React, { useEffect } from "react";
import LandingAuth from "./components/global/LandingAuth";
import { BrowserRouter as Router } from "react-router-dom";
import $ from "jquery";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel";

import "wowjs/css/libs/animate.css";
import { WOW } from "wowjs";
import counterUp from "counterup2";
function App() {
  useEffect(() => {


    // Facts counter
    $('[data-toggle="counter-up"]').each(function () {
      counterUp(this, {
        duration: 2000,
        delay: 10,
      });
    });

    // Back to top button
    $(window).scroll(() => {
      if ($(this).scrollTop() > 100) {
        $(".back-to-top").fadeIn("slow");
      } else {
        $(".back-to-top").fadeOut("slow");
      }
    });
    $(".back-to-top").click(() => {
      $("html, body").animate({ scrollTop: 0 }, 1500, "easeInOutExpo");
      return false;
    });


  }, []);
  return (
    <>
      <Router>
        <LandingAuth />
      </Router>
    </>
  );
}

export default App;
