import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../Admin/Dashboard";
import BlogsList from "../Admin/Blogs/BlogsList";
import Login from "../global/LandingPages/Login/Login";
import AdminProctected from "./Admin/AdminProctected";
import WriterList from "../Admin/Writers/WriterList";
import AddWriter from "../Admin/Writers/AddWriter";
import ErrorPage404 from "../global/ErrorsPages/notFound404";
import UpdateWriter from "../Admin/Writers/UpdateWriter";
import ArticlesList from "../Admin/Articles/ArticlesList";
import AddArticles from "../Admin/Articles/AddArticles";
import TagsList from "../Admin/Masters/Tags/TagsList";
import AddTag from "../Admin/Masters/Tags/AddTag";
import UpdateTag from "../Admin/Masters/Tags/UpdateTag";
import CategoryList from "../Admin/Masters/Categories/CategoriesList";
import AddCategories from "../Admin/Masters/Categories/AddCategories";
import UpdateCategories from "../Admin/Masters/Categories/UpdateCategories";
import Profile from "../global/LandingPages/Profile/Profile";
import UpdateArticle from "../Admin/Articles/UpdateArticle";



const AuthContainer = () => {
  return (
    <Routes>
      <Route
        path="/admin/dashboard"
        element={<AdminProctected Component={Dashboard} />}
      />
      <Route
        path="/admin/tagList"
        element={<AdminProctected Component={TagsList} />}
      />
      <Route
        path="/admin/addTag"
        element={<AdminProctected Component={AddTag} />}
      />
      <Route
        path="/admin/updateTag"
        element={<AdminProctected Component={UpdateTag} />}
      />

      <Route
        path="/admin/categoriesList"
        element={<AdminProctected Component={CategoryList} />}
      />
      <Route
        path="/admin/addCategories"
        element={<AdminProctected Component={AddCategories} />}
      />
      <Route
        path="/admin/updateCategories"
        element={<AdminProctected Component={UpdateCategories} />}
      />

      <Route
        path="/admin/writerList"
        element={<AdminProctected Component={WriterList} />}
      />
      <Route
        path="/admin/addWriter"
        element={<AdminProctected Component={AddWriter} />}
      />

      <Route
        path="/admin/updateWriter"
        element={<AdminProctected Component={UpdateWriter} />}
      />

      <Route
        path="/admin/articlesList"
        element={<AdminProctected Component={ArticlesList} />}
      />
      <Route
        path="/admin/addArticles"
        element={<AdminProctected Component={AddArticles} />}
      />
      <Route
        path="/admin/updateArticle"
        element={<AdminProctected Component={UpdateArticle} />}
      />






      <Route path="*" element={<ErrorPage404 />} />
      <Route path="/reader/:id/profile" element={<Profile />} />



    </Routes>
  );
};

export default AuthContainer;
