import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import API from '../../../../API';
import { de } from '../../../../utils/Helper';
import Message from '../../Common/Message';

const Profile = () => {
    const { id } = useParams();
    const [alertVisible, setAlertVisible] = useState(null);
    const [writerProfile, setWriterProfile] = useState("");
    const [articles, setArticles] = useState([]);
    const [visibleCount, setVisibleCount] = useState(2);
    const [showLoadMore, setShowLoadMore] = useState(true);
    const [allArticles, setAllArticles] = useState([]);
    const [visibleArticles, setVisibleArticles] = useState([]);



    // Fetch articles from API
    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const response = await API.get('/articles'); // Adjust endpoint as necessary
                const articles = response.data;
                setWriterProfile(response.data[0].writer);
                console.log("ssssssss", articles.slice(0, 2));
                // Assuming articles is an array of articles
                setAllArticles(articles);
                setVisibleArticles(articles.slice(0, 2)); // Start by showing 2 articles
            } catch (error) {
                console.error("Error fetching articles:", error);
            }
        };

        fetchArticles();
    }, []);

    // Handle loading more articles
    const handleLoadMore = () => {
        const currentLength = visibleArticles.length;
        const newArticles = allArticles.slice(currentLength, currentLength + 2);
        setVisibleArticles([...visibleArticles, ...newArticles]);

        if (currentLength + 2 >= allArticles.length) {
            setShowLoadMore(false);
        }
    };

    // Handle showing fewer articles
    const handleShowLess = () => {
        setVisibleArticles(allArticles.slice(0, 2));
        setShowLoadMore(true);
    };




    return (
        <>
            <section className="bg-light py-3 py-md-5 py-xl-8">

                <div className="container">
                    <div className="row gy-4 gy-lg-0">
                        <div className="col-12 col-lg-4 col-xl-3">
                            {alertVisible && (
                                <Message
                                    alertVisible={alertVisible}
                                    setAlertVisible={setAlertVisible}
                                />
                            )}
                            <div className="row gy-4">
                                <div className="col-12">
                                    <div className="card widget-card border-light shadow-sm">
                                        <div className="card-header text-white bg-primary">Welcome,
                                            {writerProfile?.name}</div>
                                        <div className="card-body">
                                            <div className="text-center mb-3">
                                                <img
                                                    src={`${writerProfile?.image_url == null ? `${process.env.REACT_APP_BACK_END_SERVER_PROJPATH}upload/images/user.png` : `${process.env.REACT_APP_BACK_END_SERVER_PROJPATH}${writerProfile?.image_url}`}`}
                                                    className="img-fluid rounded-circle"
                                                    alt="Luna John"
                                                    width={400}
                                                    height={400}
                                                />

                                            </div>
                                            <h5 className="text-center mb-1">{writerProfile?.name}</h5>
                                            {/* <p className="text-center text-secondary mb-4">

                                                Number of Articles {writerProfile?.article_no}


                                            </p> */}
                                            <ul className="list-group list-group-flush mb-4">
                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                    <h6 className="m-0">Articles</h6>
                                                    <span>{allArticles?.length < 0 ? 0 : allArticles?.length}</span>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                    <h6 className="m-0">Followers</h6>
                                                    <span>999</span>
                                                </li>
                                                <li className="list-group-item d-flex justify-content-between align-items-center">
                                                    <h6 className="m-0">Like</h6>
                                                    <span>999</span>
                                                </li>
                                            </ul>
                                            <div className="d-grid m-0">
                                                <button className="btn btn-outline-primary" type="button">Follow</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="card widget-card border-light shadow-sm">
                                        <div className="card-header text-bg-primary fw-bold">Social Accounts</div>
                                        <div className="card-body">

                                            <div className='container-fuild'>
                                                <div className='row'>
                                                    <div className='col-md-3'>
                                                        <a href="https://github.com/sanketbodke" target="_blank" rel="noopener noreferrer">
                                                            <i className="fab fa-github social-media" />
                                                        </a>

                                                    </div>

                                                    <div className='col-md-3'>
                                                        <a href="https://www.instagram.com/imsanketbodke/" target="blank"><i className="fab fa-instagram social-media" /></a>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <a href="https://www.instagram.com/imsanketbodke/" target="blank"><i className="fab fa-facebook social-media" /></a>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <a href="https://www.instagram.com/imsanketbodke/" target="blank"><i className="fab fa-twitter social-media" /></a>
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    {/* <div className="card widget-card border-light shadow-sm">
                                        <div className="card-header text-bg-primary">About Me</div>
                                        <div className="card-body">
                                            <ul className="list-group list-group-flush mb-0">
                                                <li className="list-group-item">
                                                    <h6 className="mb-1">
                                                        <span className="bii bi-mortarboard-fill me-2" />
                                                        Education
                                                    </h6>
                                                    <span>M.S Computer Science</span>
                                                </li>
                                                <li className="list-group-item">
                                                    <h6 className="mb-1">
                                                        <span className="bii bi-geo-alt-fill me-2" />
                                                        Location
                                                    </h6>
                                                    <span>Mountain View, California</span>
                                                </li>
                                                <li className="list-group-item">
                                                    <h6 className="mb-1">
                                                        <span className="bii bi-building-fill-gear me-2" />
                                                        Company
                                                    </h6>
                                                    <span>GitHub Inc</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="col-12">
                                    {/* <div className="card widget-card border-light shadow-sm">
                                        <div className="card-header text-bg-primary">Skills</div>
                                        <div className="card-body">
                                            <span className="badge text-bg-primary">HTML</span>
                                            <span className="badge text-bg-primary">SCSS</span>
                                            <span className="badge text-bg-primary">Javascript</span>
                                            <span className="badge text-bg-primary">React</span>
                                            <span className="badge text-bg-primary">Vue</span>
                                            <span className="badge text-bg-primary">Angular</span>
                                            <span className="badge text-bg-primary">UI</span>
                                            <span className="badge text-bg-primary">UX</span>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-8 col-xl-9">
                            <div className="card widget-card border-light shadow-sm">
                                <div className="card-body p-4">
                                    <ul className="nav nav-tabs" id="profileTab" role="tablist">
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="overview-tab" data-bs-toggle="tab" data-bs-target="#overview-tab-pane" type="button" role="tab" aria-controls="overview-tab-pane" aria-selected="true">Overview</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="blog-tab" data-bs-toggle="tab" data-bs-target="#blog-tab-pane" type="button" role="tab" aria-controls="blog-tab-pane" aria-selected="false">Blog List</button>
                                        </li>
                                        {/* <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="email-tab" data-bs-toggle="tab" data-bs-target="#email-tab-pane" type="button" role="tab" aria-controls="email-tab-pane" aria-selected="false">Emails</button>
                                        </li> */}
                                        {/* <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="password-tab" data-bs-toggle="tab" data-bs-target="#password-tab-pane" type="button" role="tab" aria-controls="password-tab-pane" aria-selected="false">Password</button>
                                        </li> */}
                                    </ul>
                                    <div className="tab-content pt-4" id="profileTabContent">
                                        <div className="tab-pane fade show active" id="overview-tab-pane" role="tabpanel" aria-labelledby="overview-tab" tabIndex={0}>
                                            <h5 className="mb-3">About</h5>
                                            <p className="lead mb-3">{writerProfile?.description}</p>

                                        </div>
                                        <div className="tab-pane fade" id="blog-tab-pane" role="tabpanel" aria-labelledby="blog-tab" tabIndex={0}>
                                            <div className="container-fluid">

                                                <div>
                                                    <div className="row">
                                                        {visibleArticles.map(article => (
                                                            article.status === 1 ? (
                                                                <div key={article.id} className="col-md-6 mt-2 mb-2">
                                                                    <article>
                                                                        <div className="card border-0 card-fixed-height">
                                                                            <figure className="card-img-top m-0 overflow-hidden bsb-overlay-hover card-img-fixed-height">
                                                                                <a href="#!">
                                                                                    <img
                                                                                        className="img-fluid bsb-scale bsb-hover-scale-up"
                                                                                        loading="lazy"
                                                                                        src={`${article?.cover_image == null ? `${process.env.REACT_APP_BACK_END_SERVER_PROJPATH}upload/images/user.png` : `${process.env.REACT_APP_BACK_END_SERVER_PROJPATH}${article?.cover_image}`}`}
                                                                                        alt={article.category.name}
                                                                                    />
                                                                                </a>
                                                                                <figcaption>
                                                                                    <svg
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width={32}
                                                                                        height={32}
                                                                                        fill="currentColor"
                                                                                        className="bi bi-eye text-white bsb-hover-fadeInLeft"
                                                                                        viewBox="0 0 16 16"
                                                                                    >
                                                                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                                                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                                                                    </svg>
                                                                                    <h4 className="h6 text-white bsb-hover-fadeInRight mt-2">Read More</h4>
                                                                                </figcaption>
                                                                            </figure>
                                                                            <div className="card-body border bg-white p-4 card-body-fixed-height">
                                                                                <div className="entry-header">
                                                                                    <ul className="entry-meta list-unstyled d-flex mb-2">
                                                                                        <li>
                                                                                            <a className="link-primary text-decoration-none" href="#!">
                                                                                                {article.category.name}
                                                                                            </a>
                                                                                        </li>
                                                                                    </ul>
                                                                                    <h2 className="card-title entry-title h4 mb-0">
                                                                                        <a className="link-dark text-decoration-none" href="#!">
                                                                                            {article.title}
                                                                                        </a>
                                                                                    </h2>
                                                                                </div>
                                                                            </div>
                                                                            <div className="card-footer border border-top-0 bg-light p-4">
                                                                                <ul className="entry-meta list-unstyled d-flex align-items-center m-0">
                                                                                    <li>
                                                                                        <a className="fs-7 link-secondary text-decoration-none d-flex align-items-center" href="#!">
                                                                                            <svg
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                width={14}
                                                                                                height={14}
                                                                                                fill="currentColor"
                                                                                                className="bi bi-calendar3"
                                                                                                viewBox="0 0 16 16"
                                                                                            >
                                                                                                <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                                                                                                <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                                                                            </svg>
                                                                                            <span className="ms-2 fs-7">{article.published_at}</span>
                                                                                        </a>
                                                                                    </li>
                                                                                    <li>
                                                                                        <span className="px-3">•</span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <a className="link-secondary text-decoration-none d-flex align-items-center" href="#!">
                                                                                            <svg
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                                width={14}
                                                                                                height={14}
                                                                                                fill="currentColor"
                                                                                                className="bi bi-chat-dots"
                                                                                                viewBox="0 0 16 16"
                                                                                            >
                                                                                                <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                                                                                <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9.06 9.06 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.437 10.437 0 0 1-.524 2.318l-.003.011a10.722 10.722 0 0 1-.244.637c-.079.186.074.394.273.362a21.673 21.673 0 0 0 .693-.125zm.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6c0 3.193-3.004 6-7 6a8.06 8.06 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a10.97 10.97 0 0 0 .398-2z" />
                                                                                            </svg>
                                                                                            <span className="ms-2 fs-7">{article.comments}</span>
                                                                                        </a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </article>
                                                                </div>
                                                            ) : null
                                                        ))}
                                                    </div>


                                                    {showLoadMore ? (
                                                        <button onClick={handleLoadMore} className="btn btn-primary mt-4">Load More</button>
                                                    ) : (
                                                        <button onClick={handleShowLess} className="btn btn-secondary mt-4">Show Less</button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default Profile