import React, { useEffect } from "react";

const LoadFiles = () => {
  useEffect(() => {
    const loadScript = (src, callback) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      script.onload = callback;
      document.body.appendChild(script);
    };

    const loadScriptsInOrder = () => {
      loadScript(`${process.env.PUBLIC_URL}/assets/dashboard/vendor/jquery/jquery.min.js`, () => {
        loadScript(`${process.env.PUBLIC_URL}/assets/dashboard/vendor/datatables/jquery.dataTables.min.js`, () => {
          loadScript(`${process.env.PUBLIC_URL}/assets/dashboard/vendor/datatables/dataTables.bootstrap4.min.js`, () => {
            loadScript(`${process.env.PUBLIC_URL}/assets/dashboard/js/demo/datatables-demo.js`, () => {
              loadScript(`${process.env.PUBLIC_URL}/assets/dashboard/js/sb-admin-2.min.js`);
            });
          });
        });
      });
    };

    loadScriptsInOrder();

    return () => {
      const scripts = document.querySelectorAll(
        `script[src*="${process.env.PUBLIC_URL}/assets/dashboard"]`
      );
      scripts.forEach((script) => {
        document.body.removeChild(script);
      });
    };
  }, []);

  return (
    <>
      <link
        href={`${process.env.PUBLIC_URL}/assets/dashboard/css/sb-admin-2.css`}
        rel="stylesheet"
      />
      <link
        href={`${process.env.PUBLIC_URL}/assets/dashboard/vendor/datatables/dataTables.bootstrap4.min.css`}
        rel="stylesheet"
      />
    </>
  );
};

export default LoadFiles;
