import React, { useState } from "react";
import LoadFiles from "../../../Admin/Common/LoadFiles";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Message from "../../Common/Message";
import API from "../../../../API";
import { en } from "../../../../utils/Helper";
import { useNavigate, Link } from "react-router-dom";
const Login = () => {
  const Navigate = useNavigate();
  const initialValues = {
    email: "",
    password: "",
    rememberMe: false,
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    // password: Yup.string()
    //   .min(8, "Password must be at least 8 characters")
    //   .matches(/[a-zA-Z]/, "Password can only contain Latin letters.")
    //   .required("Password is required"),
  });

  const initialValues2 = {
    otp: "",
  };

  const validationSchema2 = Yup.object().shape({
    otp: Yup.string().required("Required"),
  });

  const [stepHideAndShow, setStepHideAndShow] = useState(false);
  const [alertVisible, setAlertVisible] = useState(null);

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    // Simulate an async login API call
    setTimeout(() => {
      //console.log("Form data", values);
      // alert(JSON.stringify(values, null, 2));
      formFinalSubmit(values, setSubmitting);
    }, 1000);
  };

  const onSubmitOTP = (values, { setSubmitting }) => {
    setSubmitting(true);
    // Simulate an async login API call
    setTimeout(() => {
      //console.log("Form data", values);
      // alert(JSON.stringify(values, null, 2));

      verifyOtp(values, setSubmitting);
    }, 1000);
  };

  const formFinalSubmit = (values, setSubmitting) => {
    // localStorage.setItem('adminLogin',"bhavin");
    // Navigate("/admin/dashboard");
    // return;
    API.post("/login", {
      email: en(values.email),
      password: en(values.password),
    })
      .then((res) => {
        if (res.data.status === "success") {


          if (res.data.data.role == "admin") {
            localStorage.setItem('token', JSON.stringify(res.data));

            Navigate("/admin/dashboard");
          } else {
            Navigate("/reader/blogs");
          }
          setAlertVisible(res); // No unique key here
          setSubmitting(false);
          //setStepHideAndShow(true); //this is for the otp box show
        }
      })
      .catch((error) => {
        console.log(error);
        setAlertVisible(error); // No unique key here
        setSubmitting(false);
      });
  };

  const verifyOtp = (values, setSubmitting) => {
    API.post("/verifyOtp", {
      otp: en(values.otp),
    })
      .then((res) => {
        console.log(res);
        return;
        if (res.data.status === "success") {
          console.log(res);
          setAlertVisible(res); // No unique key here
          setSubmitting(false);
          Navigate("/user/dashboard");
        }
      })
      .catch((error) => {
        console.log(error);
        setAlertVisible(error); // No unique key here
        setSubmitting(false);
      });
  };
  return (
    <>
      <div
        className="container-fluid bg-gradient-primary"
        style={{ height: "100vh" }}
      >
        {/* Outer Row */}
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-12 col-md-9">
            {alertVisible && (
              <Message
                alertVisible={alertVisible}
                setAlertVisible={setAlertVisible}
              />
            )}

            <div className="card o-hidden border-0 shadow-lg my-5 mt-5">
              <div className="card-body p-0">
                {/* Nested Row within Card Body */}
                <div className="row">
                  <div className="col-lg-6 d-none d-lg-block bg-login-image" />
                  <div className="col-lg-6">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                      </div>
                      {stepHideAndShow == false ? (
                        <Formik
                          initialValues={initialValues}
                          validationSchema={validationSchema}
                          onSubmit={onSubmit}
                        >
                          {({ isSubmitting }) => (
                            <Form className="user">
                              <div className="form-group">
                                <Field
                                  type="email"
                                  name="email"
                                  className="form-control form-control-user"
                                  id="exampleInputEmail"
                                  aria-describedby="emailHelp"
                                  placeholder="Enter Email Address..."
                                />
                                <ErrorMessage
                                  name="email"
                                  component="div"
                                  className="text-danger pl-3"
                                />
                              </div>
                              <div className="form-group">
                                <Field
                                  type="password"
                                  name="password"
                                  className="form-control form-control-user"
                                  id="exampleInputPassword"
                                  placeholder="Password"
                                />
                                <ErrorMessage
                                  name="password"
                                  component="div"
                                  className="text-danger  pl-3"
                                />
                              </div>
                              <div className="form-group">
                                <div className="custom-control custom-checkbox small">
                                  <Field
                                    type="checkbox"
                                    name="rememberMe"
                                    className="custom-control-input"
                                    id="customCheck"
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customCheck"
                                  >
                                    Remember Me
                                  </label>
                                </div>
                              </div>
                              <button
                                type="submit"
                                className="btn bg_custom_primary btn-user btn-block"
                                disabled={isSubmitting}
                              >
                                {isSubmitting ? (
                                  <div className="spinner-border spinner-border-sm"></div>
                                ) : (
                                  "Login"
                                )}
                              </button>
                            </Form>
                          )}
                        </Formik>
                      ) : (
                        "null"
                        // <Formik
                        //   initialValues={initialValues2}
                        //   validationSchema={validationSchema2}
                        //   onSubmit={onSubmitOTP}
                        // >
                        //   {({ isSubmitting }) => (
                        //     <Form className="user">
                        //       <div className="form-group">
                        //         <Field
                        //           type="text"
                        //           name="otp"
                        //           className="form-control form-control-user"
                        //           id="exampleInputOTP"
                        //           placeholder="Enter OTP..."
                        //         />
                        //         <ErrorMessage
                        //           name="otp"
                        //           component="div"
                        //           className="text-danger pl-3"
                        //         />
                        //       </div>
                        //       <button
                        //         type="submit"
                        //         className="btn bg_custom_primary btn-user btn-block"
                        //         disabled={isSubmitting}
                        //       >
                        //         {isSubmitting ? (
                        //           <div className="spinner-border spinner-border-sm"></div>
                        //         ) : (
                        //           "Submit OTP"
                        //         )}
                        //       </button>
                        //     </Form>
                        //   )}
                        // </Formik>
                      )}

                      <hr />
                      <div className="text-center">
                        <a className="small" href="forgot-password.html">
                          Forgot Password?
                        </a>
                      </div>
                      <div className="text-center">
                        <Link className="small" to="/registration">
                          Create an Account!
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoadFiles />
    </>
  );
};

export default Login;
