import React from 'react'

const PostContent = ({ blogData }) => {
    return (
        <><article className="mb-4">
            <div className="container px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-10 col-lg-8 col-xl-7">
                        <h2>{blogData?.title}</h2>

                        <img alt="" style={{
                            aspectRatio: "1920/1690", maxWidth: "100%",
                            height: "auto"
                        }} width="700" height="301" loading="eager" role="presentation" src={`${process.env.REACT_APP_BACK_END_SERVER_PROJPATH}${blogData.cover_image}`} />
                        <div className="mt-5" dangerouslySetInnerHTML={{ __html: blogData.content }} />

                    </div>
                </div>
            </div>
        </article></>
    )
}

export default PostContent