import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../../../../API';
import Loader from '../../../global/Common/Loader';
import SideBar from '../../Common/SideBar';
import Navbar from '../../Common/Navbar';
import Message from '../../../global/Common/Message';
import LoadFiles from '../../Common/LoadFiles';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

const TagsList = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true); // State to track loading
    const [tags, setTags] = useState([]); // Initialize as an empty array
    const [alertVisible, setAlertVisible] = useState(null);
    const [sidebarToggled, setSidebarToggled] = useState(false);
    const [first, setFirst] = useState(0); // State for pagination first row
    const [rows, setRows] = useState(10); // State for pagination rows per page

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false); // Set loading to false after some delay
        }, 1000); // Example: Simulate 2 seconds delay
    }, []);

    useEffect(() => {
        fetchTags();
    }, [first, rows]);

    const fetchTags = async () => {
        // Adjust based on where you store your token
        try {
            const response = await API.get("/tags");
            if (response.data && Array.isArray(response.data)) {
                setTags(response.data);
            } else {
                console.error("Invalid response structure:", response.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleToggle = () => {
        setSidebarToggled(!sidebarToggled);
    };

    const handleEdit = (tag) => {
        console.log("Edit tag with ID:", tag);
        navigate("/admin/updateTag", { state: { data: tag } });
    };

    const handleDelete = (id) => {
        if (window.confirm("Are you sure you want to delete this data?")) {
            API.delete(`/tags/${id}`)
                .then((res) => {
                    if (res.data.status === "success") {
                        setTags((prevTags) => prevTags.filter(tag => tag.id !== id));
                        setAlertVisible(res);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setAlertVisible(error);
                });
        }
    };

    const handleToggleStatus = (id, currentStatus) => {
        const newStatus = currentStatus === 0 ? 1 : 0;
        setTags(tags.map(tag => tag.id === id ? { ...tag, status: newStatus } : tag));
        API.put(`/tags/status/${id}`, { status: newStatus })
            .then((res) => {
                if (res.data.status === "success") {
                    setAlertVisible(res);
                }
            })
            .catch((error) => {
                console.log(error);
                setAlertVisible(error);
            });
    };

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div id="wrapper">
                    <SideBar sidebarToggled={sidebarToggled} handleToggle={handleToggle} />
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            <Navbar handleToggle={handleToggle} />
                            {alertVisible && (
                                <Message
                                    alertVisible={alertVisible}
                                    setAlertVisible={setAlertVisible}
                                />
                            )}
                            <div className="container-fluid">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <h6 className="m-3 font-weight-bold text-primary">Tag List</h6>
                                            </div>
                                            <div className='col-md-6 text-lg-right'>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary mt-2"
                                                    onClick={() => navigate("/admin/addTag")}
                                                >
                                                    Add Tag
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <DataTable value={tags} paginator rows={rows} first={first} onPage={onPageChange} totalRecords={tags.length}>
                                                <Column field="id" header="SR NO." body={(rowData, { rowIndex }) => rowIndex + 1 + first} style={{ width: '10%' }} />
                                                <Column field="name" header="Name" style={{ width: '40%' }} />
                                                <Column field="status" header="Status" body={(rowData) => (
                                                    <div className="form-check form-switch ml-3">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            role="switch"
                                                            id={`statusSwitch-${rowData.id}`}
                                                            checked={rowData.status == 1}
                                                            onChange={() => handleToggleStatus(rowData.id, rowData.status)}
                                                        />
                                                    </div>
                                                )} style={{ width: '20%' }} />
                                                <Column header="Action" body={(rowData) => (
                                                    <>
                                                        <button
                                                            className="btn btn-warning me-2 mb-2"
                                                            onClick={() => handleEdit(rowData)}
                                                        >
                                                            <i className="fas fa-edit"/>
                                                        </button>
                                                        <button
                                                            className="btn btn-danger me-2 mb-2"
                                                            onClick={() => handleDelete(rowData.id)}
                                                        >
                                                            <i className="fas fa-trash"/>
                                                        </button>
                                                    </>
                                                )} style={{ width: '30%', textAlign: 'center' }} />
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <LoadFiles />
        </>
    );
}

export default TagsList;
