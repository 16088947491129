import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import API from '../../../../API';
import Loader from '../../../global/Common/Loader';
import SideBar from '../../Common/SideBar';
import Navbar from '../../Common/Navbar';
import Message from '../../../global/Common/Message';
import LoadFiles from '../../Common/LoadFiles';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

const CategoryList = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const [alertVisible, setAlertVisible] = useState(null);
    const [sidebarToggled, setSidebarToggled] = useState(false);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }, []);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await API.get("/categories");
                if (response.data && Array.isArray(response.data)) {
                    setCategories(response.data);
                } else {
                    console.error("Invalid response structure:", response.data);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchCategories();
    }, []);

    const handleToggle = () => {
        setSidebarToggled(!sidebarToggled);
    };

    const handleEdit = (category) => {
        navigate("/admin/updateCategories", { state: { data: category } });
    };

    const handleDelete = (id) => {
        if (window.confirm("Are you sure you want to delete this data?")) {
            API.delete(`/categories/${id}`)
                .then((res) => {
                    if (res.data.status === "success") {
                        setCategories((prevCategories) => prevCategories.filter(category => category.id !== id));
                        setAlertVisible(res);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setAlertVisible(error);
                });
        }
    };

    const handleToggleStatus = (id, currentStatus) => {
        const newStatus = currentStatus === 0 ? 1 : 0;
        setCategories(categories.map(category => category.id === id ? { ...category, status: newStatus } : category));
        API.put(`/categories/status/${id}`, { status: newStatus })
            .then((res) => {
                if (res.data.status === "success") {
                    setAlertVisible(res);
                }
            })
            .catch((error) => {
                console.log(error);
                setAlertVisible(error);
            });
    };

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div id="wrapper">
                    <SideBar sidebarToggled={sidebarToggled} handleToggle={handleToggle} />
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            <Navbar handleToggle={handleToggle} />
                            {alertVisible && (
                                <Message
                                    alertVisible={alertVisible}
                                    setAlertVisible={setAlertVisible}
                                />
                            )}
                            <div className="container-fluid">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <h6 className="m-3 font-weight-bold text-primary">Category List</h6>
                                            </div>
                                            <div className='col-md-6 text-lg-right'>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary mt-2"
                                                    onClick={() => navigate("/admin/addCategories")}
                                                >
                                                    Add Category
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <DataTable
                                                value={categories}
                                                paginator
                                                rows={rows}
                                                first={first}
                                                onPage={onPageChange}
                                                totalRecords={categories.length}
                                            // rowsPerPageOptions={[10, 25, 50]}
                                            >
                                                <Column field="id" header="SR NO." body={(rowData, { rowIndex }) => rowIndex + 1 + first} className='text-center' style={{ width: '10%' }} />
                                                <Column field="name" header="Name" className='text-center' style={{ width: '40%' }} />
                                                <Column field="status" header="Status" body={(rowData) => (
                                                    <div className="form-check form-switch ml-3">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            role="switch"
                                                            id={`statusSwitch-${rowData.id}`}
                                                            checked={rowData.status === 1}
                                                            onChange={() => handleToggleStatus(rowData.id, rowData.status)}
                                                        />
                                                    </div>
                                                )} className='text-center' style={{ width: '20%' }} />
                                                <Column header="Action" body={(rowData) => (
                                                    <>
                                                        <button
                                                            className="btn btn-warning me-2 mb-2"
                                                            onClick={() => handleEdit(rowData)}
                                                        >
                                                            <i className="fas fa-edit" />
                                                        </button>
                                                        <button
                                                            className="btn btn-danger me-2 mb-2"
                                                            onClick={() => handleDelete(rowData.id)}
                                                        >
                                                            <i className="fas fa-trash" />
                                                        </button>
                                                    </>
                                                )} className='text-center' style={{ width: '30%' }} />
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <LoadFiles />
        </>
    );
};

export default CategoryList;
