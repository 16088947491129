import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const AdminProtected = (props) => {
  const { Component } = props;
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");


    if (!token) {
      navigate("/login");
      return;
    }

    try {
      const decodedToken = JSON.parse(token).data.expires_at;
      const currentTime = Date.now() / 1000; // current time in seconds

      console.log("currentTime---->",currentTime);
      console.log("decodedToken--->",decodedToken);



      if (decodedToken < currentTime) {
        // Token has expired
        localStorage.removeItem("token"); // Clear the expired token
        navigate("/login");
      }
    } catch (error) {
      // Invalid token
      console.error("Token decoding error:", error);
      localStorage.removeItem("token"); // Clear the invalid token
      navigate("/login");
    }
  }, [navigate]);

  return <Component />;
};

export default AdminProtected;
