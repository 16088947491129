import React from "react";

const FeaturedArticles = () => {
  return (
    <>
      {" "}
      <div className="container-fluid bg-light py-5">
        <div className="container py-5">
          <div
            className="mx-auto text-center wow fadeIn"
            data-wow-delay="0.1s"
            style={{ maxWidth: 700 }}
          >
            <div className="btn btn-sm border rounded-pill text-primary px-3 mb-3">
              Featured Articles
            </div>
            <h1 className="mb-4">Explore Our Recent Featured Articles</h1>
          </div>
          <div className="row g-4">
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
              <div className="case-item position-relative overflow-hidden rounded mb-2">
                <img
                  className="img-fluid"
                  src="/assets/img/project-1.jpg"
                  alt="no_img"
                />
                <a className="case-overlay text-decoration-none" href="#">
                  <small>Robotic Automation</small>
                  <h5 className="lh-base text-white mb-3">
                    Lorem elitr magna stet eirmod labore amet labore clita
                  </h5>
                  <span className="btn btn-square btn-primary">
                    <i className="fa fa-arrow-right" />
                  </span>
                </a>
              </div>
            </div>
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.5s">
              <div className="case-item position-relative overflow-hidden rounded mb-2">
                <img
                  className="img-fluid"
                  src="/assets/img/project-2.jpg"
                  alt="no_img"
                />
                <a className="case-overlay text-decoration-none" href="#">
                  <small>Machine learning</small>
                  <h5 className="lh-base text-white mb-3">
                    Lorem elitr magna stet eirmod labore amet labore clita
                  </h5>
                  <span className="btn btn-square btn-primary">
                    <i className="fa fa-arrow-right" />
                  </span>
                </a>
              </div>
            </div>
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.7s">
              <div className="case-item position-relative overflow-hidden rounded mb-2">
                <img
                  className="img-fluid"
                  src="/assets/img/project-3.jpg"
                  alt="no_img"
                />
                <a className="case-overlay text-decoration-none" href="#">
                  <small>Predictive Analysis</small>
                  <h5 className="lh-base text-white mb-3">
                    Lorem elitr magna stet eirmod labore amet labore clita
                  </h5>
                  <span className="btn btn-square btn-primary">
                    <i className="fa fa-arrow-right" />
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturedArticles;
