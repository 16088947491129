import React, { useEffect, useState } from 'react';
import SideBar from '../Common/SideBar';
import Navbar from '../Common/Navbar';
import LoadFiles from '../Common/LoadFiles';
import Loader from '../../global/Common/Loader';
import { useNavigate } from 'react-router-dom';
import API from '../../../API';
import Message from '../../global/Common/Message';
import { en2 } from '../../../utils/Helper';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

const WriterList = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true); // State to track loading
    const [writers, setWriters] = useState([]); // Initialize as an empty array
    const [alertVisible, setAlertVisible] = useState(null);
    const [sidebarToggled, setSidebarToggled] = useState(false);
    const [first, setFirst] = useState(0); // State for pagination first row
    const [rows, setRows] = useState(10); // State for pagination rows per page

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false); // Set loading to false after some delay
        }, 1000); // Example: Simulate 1 second delay
    }, []);

    useEffect(() => {
        fetchWriters();
    }, [first, rows]);

    const fetchWriters = async () => {
        try {
            const response = await API.get("/writer");
            if (response.data && response.data.data) {
                const parsedData = JSON.parse(response.data.data);
                if (Array.isArray(parsedData)) {
                    setWriters(parsedData);
                } else {
                    console.error("Expected an array but received:", parsedData);
                }
            } else {
                console.error("Invalid response structure:", response.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleToggle = () => {
        setSidebarToggled(!sidebarToggled);
    };

    const handleEdit = (writer) => {
        navigate("/admin/updateWriter", { state: { data: writer } });
    };

    const handleDelete = (id) => {
        if (window.confirm("Are you sure you want to delete this data?")) {
            API.delete(`/writer/${id}`)
                .then((res) => {
                    if (res.data.status === "success") {
                        setWriters((prevWriters) => prevWriters.filter(writer => writer.id !== id));
                        setAlertVisible(res);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setAlertVisible(error);
                });
        }
    };

    const handleToggleStatus = (id, currentStatus) => {
        const newStatus = currentStatus === 0 ? 1 : 0;
        setWriters(writers.map(writer =>
            writer.id === id ? { ...writer, status: newStatus } : writer
        ));
        API.put(`/writer/status/${id}`, {})
            .then((res) => {
                if (res.data.status === "success") {
                    setAlertVisible(res);
                }
            })
            .catch((error) => {
                console.log(error);
                setAlertVisible(error);
            });
    };

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div id="wrapper">
                    <SideBar sidebarToggled={sidebarToggled} handleToggle={handleToggle} />
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            <Navbar handleToggle={handleToggle} />
                            {alertVisible && (
                                <Message
                                    alertVisible={alertVisible}
                                    setAlertVisible={setAlertVisible}
                                />
                            )}
                            <div className="container-fluid">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <h6 className="m-3 font-weight-bold text-primary">Writer List</h6>
                                            </div>
                                            <div className='col-md-6 text-lg-right'>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary mt-2"
                                                    onClick={() => navigate("/admin/addWriter")}
                                                >
                                                    Add Writer
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <DataTable
                                                value={writers}
                                                paginator
                                                rows={rows}
                                                first={first}
                                                onPage={onPageChange}
                                                totalRecords={writers.length}
                                            >
                                                <Column
                                                    field="id"
                                                    header="ID"
                                                    body={(rowData, { rowIndex }) => rowIndex + 1 + first}
                                                    style={{ width: '5%' }}
                                                />
                                                <Column
                                                    field="image_url"
                                                    header="Image"
                                                    body={(rowData) => (
                                                        <img
                                                            src={`${process.env.REACT_APP_BACK_END_SERVER_PROJPATH}${rowData.image_url || "upload/images/user.png"}`}
                                                            alt={rowData.name}
                                                            style={{
                                                                width: '65px',
                                                                height: '65px',
                                                                borderRadius: '50%',
                                                                display: 'block',
                                                                margin: 'auto'
                                                            }}
                                                        />
                                                    )}
                                                    style={{ width: '10%' }}
                                                />
                                                <Column
                                                    field="name"
                                                    header="Name"
                                                    style={{ width: '15%' }}
                                                />
                                                <Column
                                                    field="email"
                                                    header="Email"
                                                    style={{ width: '15%' }}
                                                />
                                                <Column
                                                    field="mobile"
                                                    header="Mobile"
                                                    style={{ width: '10%' }}
                                                />
                                                <Column
                                                    field="description"
                                                    header="Description"
                                                    body={(rowData) => (
                                                        rowData.description.length > 20
                                                            ? rowData.description.substring(0, 20) + "..."
                                                            : rowData.description
                                                    )}
                                                    style={{ width: '10%' }}
                                                />
                                                <Column
                                                    field="article_no"
                                                    header="Article No"
                                                    style={{ width: '10%' }}
                                                />
                                                <Column
                                                    field="status"
                                                    header="Status"
                                                    body={(rowData) => (
                                                        <div className="form-check form-switch ml-3">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                role="switch"
                                                                id={`statusSwitch-${rowData.id}`}
                                                                checked={rowData.status === 1}
                                                                onChange={() => handleToggleStatus(rowData.id, rowData.status)}
                                                            />
                                                        </div>
                                                    )}
                                                    style={{ width: '5%' }}
                                                />
                                                <Column
                                                    header="Action"
                                                    body={(rowData) => (
                                                        <>
                                                            <button
                                                                className="btn btn-warning me-2 mb-2"
                                                                onClick={() => handleEdit(rowData)}
                                                            >
                                                               <i className="fas fa-edit" />
                                                            </button>
                                                            <button
                                                                className="btn btn-danger me-2 mb-2"
                                                                onClick={() => handleDelete(rowData.id)}
                                                            >
                                                                 <i className="fas fa-trash" />
                                                            </button>
                                                            <a
                                                                href={`/reader/${en2(rowData.id)}/profile`}
                                                                target='__blank'
                                                                className="btn btn-info me-2 mb-2"
                                                            >
                                                                <i className="fas fa-eye"/>
                                                            </a>
                                                        </>
                                                    )}
                                                    style={{ width: '15%', textAlign: 'center' }}
                                                />
                                            </DataTable>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <LoadFiles />
        </>
    );
}

export default WriterList;
