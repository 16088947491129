import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const SideBar = ({ sidebarToggled, handleToggle }) => {
  const sidebarToggleRef = useRef(null);
  const sidebarToggleTopRef = useRef(null);
  const sidebarRef = useRef(null);
  const scrollToTopRef = useRef(null);

  useEffect(() => {
    const handleSidebarToggle = () => {
      handleToggle(); // Call the handleToggle function passed from Dashboard
      // Additional logic to collapse sidebar content if needed
      if (!sidebarToggled) {
        // Code to hide sidebar content when toggling on
      }
    };

    // Determine the class based on the sidebarToggled prop

    const handleResize = () => {
      const sidebar = sidebarRef.current;
      if (sidebar) {
        if (window.innerWidth < 768) {
          const collapseElement = sidebar.querySelector(".collapse");
          if (collapseElement) {
            collapseElement.classList.remove("show");
          }
        }
        if (window.innerWidth < 480 && !sidebar.classList.contains("toggled")) {
          document.body.classList.add("sidebar-toggled");
          sidebar.classList.add("toggled");
          const collapseElement = sidebar.querySelector(".collapse");
          if (collapseElement) {
            collapseElement.classList.remove("show");
          }
        }
      }
    };

    const handleScroll = (e) => {
      if (window.innerWidth > 768) {
        const delta = e.wheelDelta || -e.detail;
        if (sidebarRef.current) {
          sidebarRef.current.scrollTop += 30 * (delta < 0 ? 1 : -1);
        }
        e.preventDefault();
      }
    };

    const handleDocumentScroll = () => {
      if (window.scrollY > 100 && scrollToTopRef.current) {
        scrollToTopRef.current.style.display = "block";
      } else if (scrollToTopRef.current) {
        scrollToTopRef.current.style.display = "none";
      }
    };

    const handleScrollToTopClick = (e) => {
      e.preventDefault();
      const target = document.querySelector(
        e.currentTarget.getAttribute("href")
      );
      if (target) {
        window.scrollTo({ top: target.offsetTop, behavior: "smooth" });
      }
    };

    if (sidebarToggleRef.current) {
      sidebarToggleRef.current.addEventListener("click", handleSidebarToggle);
    }

    if (sidebarToggleTopRef.current) {
      sidebarToggleTopRef.current.addEventListener(
        "click",
        handleSidebarToggle
      );
    }

    if (sidebarRef.current) {
      sidebarRef.current.addEventListener("wheel", handleScroll);
    }

    if (scrollToTopRef.current) {
      scrollToTopRef.current.addEventListener("click", handleScrollToTopClick);
    }

    window.addEventListener("resize", handleResize);
    document.addEventListener("scroll", handleDocumentScroll);

    return () => {
      if (sidebarToggleRef.current) {
        sidebarToggleRef.current.removeEventListener(
          "click",
          handleSidebarToggle
        );
      }

      if (sidebarToggleTopRef.current) {
        sidebarToggleTopRef.current.removeEventListener(
          "click",
          handleSidebarToggle
        );
      }

      if (sidebarRef.current) {
        sidebarRef.current.removeEventListener("wheel", handleScroll);
      }

      if (scrollToTopRef.current) {
        scrollToTopRef.current.removeEventListener(
          "click",
          handleScrollToTopClick
        );
      }

      window.removeEventListener("resize", handleResize);
      document.removeEventListener("scroll", handleDocumentScroll);
    };
  }, []);

  const sidebarClass = `navbar-nav bg-gradient-primary sidebar sidebar-dark accordion ${sidebarToggled ? "toggled" : ""
    }`;

  return (
    <>
      <ul className={sidebarClass} id="accordionSidebar" ref={sidebarRef}>
        {/* Sidebar - Brand */}
        <a
          className="sidebar-brand d-flex align-items-center justify-content-center"
          href="index.html"
        >
          <div className="sidebar-brand-icon">
            <img
              src="/assets/img/logo.png"
              width={45}
              height={45}
              className="rounded-circle"
              alt="AI"
            />
          </div>
          {/* <div className="sidebar-brand-text mx-3">
            <sup>Writes</sup>
          </div> */}
        </a>
        {/* Divider */}
        <hr className="sidebar-divider my-0" />
        {/* Nav Item - Dashboard */}
        <li className="nav-item active">
          <Link className="nav-link" to="/admin/dashboard">
            <i className="fas fa-fw fa-tachometer-alt" />
            <span>Dashboard</span>
          </Link>
        </li>
        {/* Divider */}
        <hr className="sidebar-divider" />
        {/* Heading */}
        <div className="sidebar-heading">Interface</div>
        {/* Nav Item - Pages Collapse Menu */}
        <li className="nav-item">
          <a
            className="nav-link collapsed"
            href="#"
            data-toggle="collapse"
            data-target="#collapseMasters"
            aria-expanded="true"
            aria-controls="collapseMasters"
          >
            <i className="fas fa-fw fa-cog" />
            <span>Masters Management</span>
          </a>
          <div
            id="collapseMasters"
            className="collapse"
            aria-labelledby="headingMasters"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header">Actions</h6>
              <Link
                className="collapse-item"
                to="/admin/tagList"
                onClick={() => {
                  // Close the collapse menu when a link is clicked
                  const sidebar = document.getElementById("accordionSidebar");
                  if (sidebar) {
                    const collapseElements =
                      sidebar.querySelectorAll(".collapse.show");
                    collapseElements.forEach((element) => {
                      element.classList.remove("show");
                    });
                  }
                }}
              >
                Tags
              </Link>
              <Link
                className="collapse-item"
                to="/admin/categoriesList"
                onClick={() => {
                  // Close the collapse menu when a link is clicked
                  const sidebar = document.getElementById("accordionSidebar");
                  if (sidebar) {
                    const collapseElements =
                      sidebar.querySelectorAll(".collapse.show");
                    collapseElements.forEach((element) => {
                      element.classList.remove("show");
                    });
                  }
                }}
              >
                Categories
              </Link>
            </div>
          </div>
        </li>

        <li className="nav-item">
          <a
            className="nav-link collapsed"
            href="#"
            data-toggle="collapse"
            data-target="#collapseWriterManagement"
            aria-expanded="true"
            aria-controls="collapseWriterManagement"
          >
            <i className="fas fa-fw fa-cog" />
            <span>Writer Management</span>
          </a>
          <div
            id="collapseWriterManagement"
            className="collapse"
            aria-labelledby="headingWriterManagement"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header">Actions</h6>
              <Link
                className="collapse-item"
                to="/admin/writerList"
                onClick={() => {
                  // Close the collapse menu when a link is clicked
                  const sidebar = document.getElementById("accordionSidebar");
                  if (sidebar) {
                    const collapseElements =
                      sidebar.querySelectorAll(".collapse.show");
                    collapseElements.forEach((element) => {
                      element.classList.remove("show");
                    });
                  }
                }}
              >
                Writers
              </Link>
            </div>
          </div>
        </li>

        <li className="nav-item">
          <a
            className="nav-link collapsed"
            href="#"
            data-toggle="collapse"
            data-target="#collapseTwo"
            aria-expanded="true"
            aria-controls="collapseTwo"
          >
            <i className="fas fa-fw fa-cog" />
            <span>Articles Management</span>
          </a>
          <div
            id="collapseTwo"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header">Actions</h6>
              <Link
                className="collapse-item"
                to="/admin/articlesList"
                onClick={() => {
                  // Close the collapse menu when a link is clicked
                  const sidebar = document.getElementById("accordionSidebar");
                  if (sidebar) {
                    const collapseElements =
                      sidebar.querySelectorAll(".collapse.show");
                    collapseElements.forEach((element) => {
                      element.classList.remove("show");
                    });
                  }
                }}
              >
                Articles
              </Link>
            </div>
          </div>
        </li>

        {/* Nav Item - Utilities Collapse Menu */}
        {/* <hr className="sidebar-divider d-none d-md-block" /> */}
        {/* <div className="text-center d-none d-md-inline">
          <button
            className="rounded-circle border-0"
            id="sidebarToggle"
            ref={sidebarToggleRef}
          />
        </div> */}
      </ul>

      <a href="#top" className="scroll-to-top" ref={scrollToTopRef}>
        Scroll to Top
      </a>
    </>
  );
};

export default SideBar;
