import SideBar from "./Common/SideBar";
import Navbar from "./Common/Navbar";
import DashboardData from "./Common/DashboardData";
import { useEffect, useState } from "react";
import LoadFiles from "./Common/LoadFiles";
import Loader from "../global/Common/Loader";

const Dashboard = () => {
  const [sidebarToggled, setSidebarToggled] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // State to track loading

  useEffect(() => {
    // Simulate loading delay (replace with actual logic to check if LoadFiles is loaded)
    setTimeout(() => {
      setIsLoading(false); // Set loading to false after some delay
    }, 1000); // Example: Simulate 2 seconds delay
  }, []);

  const handleToggle = () => {
    setSidebarToggled(!sidebarToggled);
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : <div id="wrapper">
        <SideBar sidebarToggled={sidebarToggled} handleToggle={handleToggle} />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbar handleToggle={handleToggle} />
            <div className="container-fluid">
              <DashboardData />
            </div>

          </div>
        </div>
      </div>
      }


      <LoadFiles />
    </>
  );
};

export default Dashboard;
