import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import Footer from "../../Common/Footer";
import Navbar from "../../Common/Navbar ";
import API from "../../../../API";
import Loader from "../../Common/Loader";
import ErrorPage404 from "../../ErrorsPages/notFound404";
import PostContent from "../../Common/Articles/PostContent";
import Profile from "../../Common/Articles/Profile";
import OtherArticles from "../../Common/Articles/OtherArticles";

const Articles = () => {
  const { id } = useParams(); // Get the id from the URL
  const [blogData, setBlogData] = useState(null); // State to hold the blog data
  const [loading, setLoading] = useState(true); // State to show a loading indicator
  const [error, setError] = useState(null); // State to show an error message

  useEffect(() => {
    // Fetch the blog data when the component mounts
    API
      .get(`/writer/${id}/article`)
      .then((response) => {
        const data = JSON.parse(response.data.data); // Parse the JSON string
        console.log("dddddddd", data);
        setBlogData(data); // Set the blog data
        setLoading(false); // Set loading to false
      })
      .catch((error) => {
        console.error("Error fetching blog data:", error);
        setError(error); // Set the error
        setLoading(false); // Set loading to false
      });
  }, [id]);

  if (loading) {
    return <><Loader /></>; // Show a loading message
  }

  if (error) {
    return <div><ErrorPage404 /></div>; // Show an error message
  }

  return (
    <>
      <div className="bg-dark">
        <Navbar />
      </div>

      <div>
        {/* Page Header*/}
        <header
          className="masthead"
          style={{
            backgroundImage: "url('/assets/img/post-bg.jpg')",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
          }}
        >
          <div className="container position-relative px-4 px-lg-5">
            <div className="row gx-4 gx-lg-5 justify-content-center">
              <div className="col-md-10 col-lg-8 col-xl-8 ">
                <div className="post-heading ">
                  <h1 className="text-white ">{blogData.title}</h1>
                  <span className="meta text-white">
                    Posted by
                    <a href="#!" className="text-white">
                      {blogData.writer?.name}
                    </a>
                    on {new Date(blogData.created_at).toLocaleDateString()}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* Post Content*/}
        <PostContent blogData={blogData} />
        <Profile blogData={blogData} />
        <OtherArticles />
      </div>
      <div className="mt-5"></div>
      <Footer />
    </>
  );
};

export default Articles;
