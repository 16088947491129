import React, { useState, useEffect } from "react";
import AllRoutes from "../Authentication/AllRoutes";
import AuthContainer from "../Authentication/AuthContainer";

import { useLocation } from "react-router-dom";
import ReaderRoutes from "../Authentication/ReaderRoutes";

const LandingAuth = () => {
  const [route, setRoute] = useState();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.indexOf("/admin") >= 0) {
      setRoute(2);
    } else if (location.pathname.indexOf("/reader") >= 0) {
      setRoute(3);
    } else {
      setRoute(1);
    }
  }, [location.pathname]);

  return (
    <div>
      {route === 1 ? (
        //?-----------this is for the landing page--------------
        <AllRoutes />
      ) : //?------------------------------------------------------
      route === 2 ? (
        //?-----------this is for the users page--------------
        <AuthContainer />
      ) : //?------------------------------------------------------
      route === 3 ? (
        <ReaderRoutes />
      ) : null}
    </div>
  );
};

export default LandingAuth;
