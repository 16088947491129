import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import API from '../../../../API';
import Loader from '../../../global/Common/Loader';
import SideBar from '../../Common/SideBar';
import Navbar from '../../Common/Navbar';
import Message from '../../../global/Common/Message';
import LoadFiles from '../../Common/LoadFiles';

const UpdateCategories = () => {
    const location = useLocation();
    const category = location.state?.data;
    console.log("ssss",category.name);

    const initialValues = {
        name: category?.name || '',
        status: category?.status || ''
    };

    const validationSchema = Yup.object({
        name: Yup.string()
            .required('Name is required'), // Name must be filled in
        status: Yup.string()
            .required('Status is required') // Status must be filled in
    });

    const onSubmit = (values, { setSubmitting }) => {
        console.log('Form data', values);
        API.put(`/categories/${category.id}`, { name: values.name, status: values.status })
            .then((res) => {
                if (res.data.status === "success") {
                    setAlertVisible(res);
                    setSubmitting(false);
                    navigate("/admin/categoriesList");
                }
            })
            .catch((error) => {
                console.log(error);
                setAlertVisible(error);
                setSubmitting(false);
            });
    };

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true); // State to track loading
    const [alertVisible, setAlertVisible] = useState(null);

    useEffect(() => {
        // Simulate loading delay (replace with actual logic to check if LoadFiles is loaded)
        setTimeout(() => {
            setIsLoading(false); // Set loading to false after some delay
        }, 1000); // Example: Simulate 2 seconds delay
    }, []);

    const [sidebarToggled, setSidebarToggled] = useState(false);

    const handleToggle = () => {
        setSidebarToggled(!sidebarToggled);
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div id="wrapper">
                    <SideBar sidebarToggled={sidebarToggled} handleToggle={handleToggle} />
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            <Navbar handleToggle={handleToggle} />
                            <div className="container-fluid">
                                <div className='row'>
                                    {alertVisible && (
                                        <Message
                                            alertVisible={alertVisible}
                                            setAlertVisible={setAlertVisible}
                                        />
                                    )}
                                    <div className='col-md-6'>
                                        <div className="mb-4">
                                            <h1 className="h3 mb-0 text-gray-800">Update Category</h1>
                                        </div>
                                    </div>
                                    <div className='col-md-6 text-lg-right'></div>
                                </div>
                            </div>

                            <div className="container">
                                <div className="card o-hidden border-0 shadow-lg">
                                    <div className="card-body p-0">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="p-4">
                                                    <Formik
                                                        initialValues={initialValues}
                                                        validationSchema={validationSchema}
                                                        onSubmit={onSubmit}
                                                    >
                                                        {({ isSubmitting }) => (
                                                            <Form className="user">
                                                                <div className="mb-3 mt-3">
                                                                    <label htmlFor="name" className="form-label">Name:</label>
                                                                    <Field type="text" className="form-control" id="name" name="name" />
                                                                    <ErrorMessage name="name" component="div" className="text-danger" />
                                                                </div>
                                                                <div className="mb-3 mt-3">
                                                                    <label htmlFor="status" className="form-label">Status:</label>
                                                                    <Field as="select" className="form-control" id="status" name="status">
                                                                        <option value="" label="Select status" />
                                                                        <option value="1" label="Active" />
                                                                        <option value="0" label="Inactive" />
                                                                    </Field>
                                                                    <ErrorMessage name="status" component="div" className="text-danger" />
                                                                </div>

                                                                <div className='d-sm-flex align-items-center justify-content-center'>
                                                                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                                                        {isSubmitting ? (
                                                                            <div className="spinner-border spinner-border-sm"></div>
                                                                        ) : (
                                                                            "Submit"
                                                                        )}
                                                                    </button>
                                                                    <button type="button" className="btn btn-danger ml-3" onClick={() => {
                                                                        navigate("/admin/categoriesList")
                                                                    }}>Back</button>
                                                                </div>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <LoadFiles />
        </>
    );
}

export default UpdateCategories;
