import React, { useState } from 'react'
import Comments from './Comments';

const Profile = ({ blogData }) => {
    const [showModal, setShowModal] = useState(false);
    return (
        <><article className="mb-4">
            <div className="container-fluid px-4 px-lg-5">
                <div className="row gx-4 gx-lg-5 justify-content-center">
                    <div className="col-md-10 col-lg-8 col-xl-7">

                        <div className=" mt-5 d-flex justify-content-center">
                            <div className="profile-card p-3">
                                <div className="d-flex align-items-center">
                                    <div className="image">
                                        <img src={`${process.env.REACT_APP_BACK_END_SERVER_PROJPATH}${blogData?.writer?.image_url}`} className="rounded-circle" width={155} />

                                    </div>
                                    <div className="ml-3 w-100" style={{ marginLeft: "14px" }}>
                                        <h4 className="mb-0 mt-0">
                                            {blogData?.writer?.name}
                                        </h4>
                                        {/* <span>Senior Journalist</span> */}
                                        <div className="p-2 mt-2 bg-primary d-flex justify-content-between rounded text-white profile-stats">
                                            <div className="d-flex flex-column">
                                                <span className="profile-articles">Articles</span>
                                                <span className="profile-number1">
                                                    {blogData?.writer?.articles_count}
                                                </span>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <span className="profile-followers">Followers</span>
                                                <span className="profile-number2">980</span>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <span className="profile-rating">Like</span>
                                                <span className="profile-number3">8.9</span>
                                            </div>
                                        </div>
                                        <div className="button mt-2 d-flex flex-row align-items-center">
                                            <button className="btn btn-sm btn-outline-primary w-100" onClick={() => setShowModal(true)}>
                                                Comment
                                            </button>
                                            <button className="btn btn-sm btn-primary w-100 ml-2">Follow</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <section className="content-item" id="comments">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <Comments showModal={showModal} setShowModal={setShowModal} />

                                        <h5>Comments</h5><span className="ml-2"></span>
                                        {/* COMMENT 1 - START */}
                                        <div className="media">
                                            <a className="float-start me-3" href="#"><img className="media-object" src={`${process.env.REACT_APP_BACK_END_SERVER_PROJPATH}upload/images/user.png`} alt /></a>
                                            <div className="media-body">
                                                <h4 className="media-heading">John Doe</h4>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                                <ul className="list-inline mb-0">
                                                    <li className="list-inline-item"><i className="fa fa-calendar" />27/02/2014</li>
                                                    <li className="list-inline-item"><i className="fa fa-thumbs-up" />13</li>
                                                </ul>
                                                <ul className="list-inline float-end mb-0">
                                                    <li className="list-inline-item"><a href>Like</a></li>
                                                    <li className="list-inline-item"><a href>Reply</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* COMMENT 1 - END */}

                                    </div>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
            </div>
        </article></>
    )
}

export default Profile