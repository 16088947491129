import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import API from '../../../API';
import Loader from '../../global/Common/Loader';
import SideBar from '../Common/SideBar';
import Navbar from '../Common/Navbar';
import Message from '../../global/Common/Message';
import LoadFiles from '../Common/LoadFiles';
import axios from 'axios';

const AddArticle = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [alertVisible, setAlertVisible] = useState(null);
    const [writers, setWriters] = useState([]);
    const [categories, setCategories] = useState([]);
    const [sidebarToggled, setSidebarToggled] = useState(false);

    const initialValues = {
        title: '',
        content: '',
        writer_id: '',
        category_id: '',
        cover_image: null
    };

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }, []);

    useEffect(() => {
        const fetchWritersAndCategories = async () => {
            try {
                const Response = await API.get('/articles/writerAndCategory');
                setWriters(JSON.parse(Response.data.writers));
                setCategories(JSON.parse(Response.data.Categories));
            } catch (error) {
                console.error('Error fetching writers and categories', error);
            }
        };

        fetchWritersAndCategories();

    }, []);

    const validationSchema = Yup.object({
        title: Yup.string().required('Title is required'),
        content: Yup.string().required('Content is required'),
        writer_id: Yup.string().required('Writer is required'),
        category_id: Yup.string().required('Category is required'),
        cover_image: Yup.mixed().required('Cover image is required')
    });

    const onSubmit = (values, { setSubmitting }) => {

        const formData = new FormData();
        formData.append('title', values.title);
        formData.append('content', values.content);
        formData.append('writer_id', values.writer_id);
        formData.append('category_id', values.category_id);
        formData.append('cover_image', values.cover_image);
        formData.append('tags', "testing");


        API.post("/articles", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((res) => {
            if (res.data.status === "success") {
                setAlertVisible(res);
                setSubmitting(false);
                navigate("/admin/articlesList");
            }
        })
        .catch((error) => {
            console.log(error);
            setAlertVisible(error);
            setSubmitting(false);
        });
    };

    const handleToggle = () => {
        setSidebarToggled(!sidebarToggled);
    };

    const editorConfiguration = {
        toolbar: [
            'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', '|',
            'fontColor', 'fontBackgroundColor', '|', 'undo', 'redo', 'imageUpload'
        ],
        image: {
            toolbar: ['imageTextAlternative', 'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight'],
            styles: [
                'full', 'alignLeft', 'alignCenter', 'alignRight'
            ]
        },
        imageResize: {
            options: [
                {
                    name: 'resizeImage:original',
                    label: 'Original',
                    value: null
                },
                {
                    name: 'resizeImage:width',
                    label: 'Small',
                    value: '300'
                },
                {
                    name: 'resizeImage:width',
                    label: 'Medium',
                    value: '600'
                },
                {
                    name: 'resizeImage:width',
                    label: 'Large',
                    value: '1000'
                }
            ]
        }
    };

    const API_url = "http://127.0.0.1:8000";
    const UPLOAD_ENDPOINT = 'public/upload/images';
    function uploadAdapter(loader) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then(file => {
                        body.append("image", file); // Ensure the field name matches the server-side field name
                        // axios.post("http://127.0.0.1:8000/api/upload-image", body)
                        axios.post("https://aiwrites.theaimatters.com/api/upload-image", body)

                            .then(response => {
                                console.log('Response:', response); // Log the response for debugging
                                if (response.data.url) {
                                    resolve({ default: response.data.url }); // Adjust according to your API response
                                } else {
                                    reject('Image URL not found in response');
                                }
                            })
                            .catch(error => {
                                console.error('Upload error:', error); // Log the error for debugging

                                // Check if the error response has a message
                                if (error.response && error.response.data && error.response.data.message) {
                                    reject(error.response.data.message); // Extract the error message
                                } else {
                                    reject('An unknown error occurred during the upload.'); // Fallback message
                                }
                            });
                    });
                });
            }
        };
    }

    // Define the plugin
    function uploadPlugin(editor) {
        editor.plugins.get('FileRepository').createUploadAdapter = loader => uploadAdapter(loader);
    }

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div id="wrapper">
                    <SideBar sidebarToggled={sidebarToggled} handleToggle={handleToggle} />
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            <Navbar handleToggle={handleToggle} />
                            <div className="container-fluid">
                                <div className='row'>
                                    {alertVisible && (
                                        <Message
                                            alertVisible={alertVisible}
                                            setAlertVisible={setAlertVisible}
                                        />
                                    )}
                                    <div className='col-md-6'>
                                        <div className="mb-4">
                                            <h1 className="h3 mb-0 text-gray-800">Add Article</h1>
                                        </div>
                                    </div>
                                    <div className='col-md-6 text-lg-right'></div>
                                </div>
                            </div>

                            <div className="container">
                                <div className="card o-hidden border-0 shadow-lg">
                                    <div className="card-body p-0">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="p-4">
                                                    <Formik
                                                        initialValues={initialValues}
                                                        validationSchema={validationSchema}
                                                        onSubmit={onSubmit}
                                                    >
                                                        {({ isSubmitting, setFieldValue }) => (
                                                            <Form className="user">
                                                                <div className="mb-3 mt-3">
                                                                    <label htmlFor="title" className="form-label">Title:</label>
                                                                    <Field type="text" className="form-control" id="title" name="title" />
                                                                    <ErrorMessage name="title" component="div" className="text-danger" />
                                                                </div>
                                                                <div className="mb-3 mt-3">
                                                                    <label htmlFor="cover_image" className="form-label">Cover Image:</label>
                                                                    <input
                                                                        type="file"
                                                                        className="form-control"
                                                                        id="cover_image"
                                                                        name="cover_image"
                                                                        onChange={(event) => {
                                                                            setFieldValue("cover_image", event.currentTarget.files[0]);
                                                                        }}
                                                                    />
                                                                    <ErrorMessage name="cover_image" component="div" className="text-danger" />
                                                                </div>
                                                                <div className="mb-3 mt-3">
                                                                    <label htmlFor="writer_id" className="form-label">Writer:</label>
                                                                    <Field as="select" className="form-control" id="writer_id" name="writer_id">
                                                                        <option value="">Select Writer</option>
                                                                        {writers.map(writer => (
                                                                            <option key={writer.id} value={writer.id}>
                                                                                {writer.name}
                                                                            </option>
                                                                        ))}
                                                                    </Field>
                                                                    <ErrorMessage name="writer_id" component="div" className="text-danger" />
                                                                </div>
                                                                <div className="mb-3 mt-3">
                                                                    <label htmlFor="category_id" className="form-label">Category:</label>
                                                                    <Field as="select" className="form-control" id="category_id" name="category_id">
                                                                        <option value="">Select Category</option>
                                                                        {categories.map(category => (
                                                                            <option key={category.id} value={category.id}>
                                                                                {category.name}
                                                                            </option>
                                                                        ))}
                                                                    </Field>
                                                                    <ErrorMessage name="category_id" component="div" className="text-danger" />
                                                                </div>
                                                                <div className="mb-3 mt-3">
                                                                    <label htmlFor="content" className="form-label">Content:</label>
                                                                    <Field name="content">
                                                                        {({ field }) => (
                                                                            <CKEditor
                                                                                editor={ClassicEditor}
                                                                                config={editorConfiguration}
                                                                                data={field.value}
                                                                                onChange={(event, editor) => {
                                                                                    const data = editor.getData();
                                                                                    field.onChange({
                                                                                        target: {
                                                                                            name: field.name,
                                                                                            value: data,
                                                                                        },
                                                                                    });
                                                                                }}
                                                                                onReady={(editor) => {
                                                                                    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                                                                                        return uploadAdapter(loader);
                                                                                    };
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <ErrorMessage name="content" component="div" className="text-danger" />
                                                                </div>
                                                                <button type="submit" className="btn btn-primary btn-user btn-block" disabled={isSubmitting}>
                                                                    {isSubmitting ? 'Please wait...' : 'Add Article'}
                                                                </button>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <LoadFiles />
                    </div>
                </div>
            )}
        </>
    );
};

export default AddArticle;
