import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import API from '../../../API';
import Loader from '../../global/Common/Loader';
import SideBar from '../Common/SideBar';
import Navbar from '../Common/Navbar';
import Message from '../../global/Common/Message';
import LoadFiles from '../Common/LoadFiles';
import axios from 'axios';

const UpdateArticle = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const [alertVisible, setAlertVisible] = useState(null);
    const [writers, setWriters] = useState([]);
    const [categories, setCategories] = useState([]);
    const [sidebarToggled, setSidebarToggled] = useState(false);
    const [article, setArticle] = useState(null);

    const writer = location.state?.data;

    const initialValues = {
        title: writer?.title || '',
        content: writer?.content || '',
        writer_id: writer?.writer_id || '',
        category_id: writer?.category_id || ''
    };
    useEffect(() => {
        // Simulate loading delay (replace with actual logic to check if LoadFiles is loaded)
        setTimeout(() => {
            setIsLoading(false); // Set loading to false after some delay
        }, 1000); // Example: Simulate 2 seconds delay
    }, []);


    useEffect(() => {
        const fetchWritersAndCategories = async () => {
            try {
                const response = await API.get('/articles/writerAndCategory');
                setWriters(JSON.parse(response.data.writers));
                setCategories(JSON.parse(response.data.Categories));
            } catch (error) {
                console.error('Error fetching writers and categories', error);
            }
        };

        fetchWritersAndCategories();
    }, []);

    const validationSchema = Yup.object({
        title: Yup.string().required('Title is required'),
        content: Yup.string().required('Content is required'),
        writer_id: Yup.string().required('Writer is required'),
        category_id: Yup.string().required('Category is required')
    });

    const onSubmit = (values, { setSubmitting }) => {
        console.log(values);

        API.put(`/articles/${writer?.id}`, { title: values.title, content: values.content, writer_id: values.writer_id, category_id: values.category_id, tags: 1 })
            .then((res) => {
                if (res.data.status === "success") {
                    setAlertVisible(res);
                    setSubmitting(false);
                    navigate("/admin/articlesList");
                }
            })
            .catch((error) => {
                console.log(error);
                setAlertVisible(error);
                setSubmitting(false);
            });
    };

    const handleToggle = () => {
        setSidebarToggled(!sidebarToggled);
    };

    const editorConfiguration = {
        toolbar: [
            'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', '|',
            'fontColor', 'fontBackgroundColor', '|', 'undo', 'redo', 'imageUpload'
        ],
        image: {
            toolbar: ['imageTextAlternative', 'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight'],
            styles: [
                'full', 'alignLeft', 'alignCenter', 'alignRight'
            ]
        },
        imageResize: {
            options: [
                {
                    name: 'resizeImage:original',
                    label: 'Original',
                    value: null
                },
                {
                    name: 'resizeImage:width',
                    label: 'Small',
                    value: '300'
                },
                {
                    name: 'resizeImage:width',
                    label: 'Medium',
                    value: '600'
                },
                {
                    name: 'resizeImage:width',
                    label: 'Large',
                    value: '1000'
                }
            ]
        }
    };

    function uploadAdapter(loader) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then(file => {
                        body.append("image", file);
                        // axios.post("http://127.0.0.1:8000/api/upload-image", body)
                        axios.post("https://aiwrites.theaimatters.com/api/upload-image", body)

                            .then(response => {
                                if (response.data.url) {
                                    resolve({ default: response.data.url });
                                } else {
                                    reject('Image URL not found in response');
                                }
                            })
                            .catch(error => {
                                console.error('Upload error:', error);
                                reject(error);
                            });
                    });
                });
            }
        };
    }

    function uploadPlugin(editor) {
        editor.plugins.get('FileRepository').createUploadAdapter = loader => uploadAdapter(loader);
    }

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div id="wrapper">
                    <SideBar sidebarToggled={sidebarToggled} handleToggle={handleToggle} />
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            <Navbar handleToggle={handleToggle} />
                            <div className="container-fluid">
                                <div className='row'>
                                    {alertVisible && (
                                        <Message
                                            alertVisible={alertVisible}
                                            setAlertVisible={setAlertVisible}
                                        />
                                    )}
                                    <div className='col-md-6'>
                                        <div className="mb-4">
                                            <h1 className="h3 mb-0 text-gray-800">Update Article</h1>
                                        </div>
                                    </div>
                                    <div className='col-md-6 text-lg-right'></div>
                                </div>
                            </div>

                            <div className="container">
                                <div className="card o-hidden border-0 shadow-lg">
                                    <div className="card-body p-0">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="p-4">
                                                    <Formik
                                                        initialValues={initialValues}
                                                        validationSchema={validationSchema}
                                                        onSubmit={onSubmit}
                                                    >
                                                        {({ isSubmitting, setFieldValue }) => (
                                                            <Form className="user">
                                                                <div className="mb-3 mt-3">
                                                                    <label htmlFor="title" className="form-label">Title:</label>
                                                                    <Field type="text" className="form-control" id="title" name="title" />
                                                                    <ErrorMessage name="title" component="div" className="text-danger" />
                                                                </div>
                                                                <div className="mb-3 mt-3">
                                                                    <label htmlFor="writer_id" className="form-label">Writer:</label>
                                                                    <Field as="select" className="form-control" id="writer_id" name="writer_id">
                                                                        <option value="">Select Writer</option>
                                                                        {writers.map(writer => (
                                                                            <option key={writer.id} value={writer.id}>
                                                                                {writer.name}
                                                                            </option>
                                                                        ))}
                                                                    </Field>
                                                                    <ErrorMessage name="writer_id" component="div" className="text-danger" />
                                                                </div>
                                                                <div className="mb-3 mt-3">
                                                                    <label htmlFor="category_id" className="form-label">Category:</label>
                                                                    <Field as="select" className="form-control" id="category_id" name="category_id">
                                                                        <option value="">Select Category</option>
                                                                        {categories.map(category => (
                                                                            <option key={category.id} value={category.id}>
                                                                                {category.name}
                                                                            </option>
                                                                        ))}
                                                                    </Field>
                                                                    <ErrorMessage name="category_id" component="div" className="text-danger" />
                                                                </div>
                                                                <div className="mb-3 mt-3">
                                                                    <label htmlFor="content" className="form-label">Content:</label>
                                                                    <CKEditor
                                                                        editor={ClassicEditor}
                                                                        config={{ extraPlugins: [uploadPlugin] }}
                                                                        data={initialValues.content}
                                                                        onChange={(event, editor) => {
                                                                            const data = editor.getData();
                                                                            setFieldValue('content', data);
                                                                        }}
                                                                    />
                                                                    <ErrorMessage name="content" component="div" className="text-danger" />
                                                                </div>
                                                                <div className='d-sm-flex align-items-center justify-content-center'>
                                                                    <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                                                                        {isSubmitting ? (
                                                                            <div className="spinner-border spinner-border-sm"></div>
                                                                        ) : (
                                                                            "Submit"
                                                                        )}
                                                                    </button>
                                                                    <button type="button" className="btn btn-danger ml-3" onClick={() => {
                                                                        navigate("/admin/articlesList")
                                                                    }}>Back</button>
                                                                </div>
                                                            </Form>
                                                        )}
                                                    </Formik>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <LoadFiles />
        </>
    );
}

export default UpdateArticle;
