import React from "react";
import { Routes, Route } from "react-router-dom";
// import Blogs from "../global/LandingPages/Blogs/Blogs";
import Profile from "../global/LandingPages/Profile/Profile";

const ReaderRoutes = () => {
  return (
    <>
      {" "}
      <Routes>
        {/* <Route path="/reader/blogs" element={<Blogs />} /> */}
        <Route path="/reader/:id/profile" element={<Profile />} />
      </Routes>
    </>
  );
};

export default ReaderRoutes;
