import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import ErrorPage404 from "../global/ErrorsPages/notFound404";
import Home from "../global/LandingPages/Home/Home";
import Registration from "../global/LandingPages/Registration/registration";
import Login from "../global/LandingPages/Login/Login";
import AdminLogin from "../global/LandingPages/AdminLogin/AdminLogin";
import Articles from "../global/LandingPages/Articles/Articles";


const AllRoutes = () => {
  return (
    <>
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />

        <Route path="/registration" element={<Registration />} />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/writer/:id/blog" element={<Articles />} />


        <Route path="*" element={<ErrorPage404 />} />
      </Routes>
    </>
  );
};

export default AllRoutes;
