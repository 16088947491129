import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const Comments = ({ showModal, setShowModal }) => {
    const initialValues = { message: '' };

    const spamWords = ['spamword1', 'spamword2', 'spamword3']; // Add your spam words here

    const validationSchema = Yup.object({
        message: Yup.string()
            .required('Message is required')
            .test('wordCount', 'Message cannot exceed 100 words', function (value) {
                const words = value ? value.split(' ') : [];
                return words.length <= 100;
            })
            .test('noURL', 'Message cannot contain URLs', function (value) {
                const urlPattern = /https?:\/\/[^\s]+/;
                return !urlPattern.test(value);
            })
            .test('noSpamWords', 'Message contains inappropriate content', function (value) {
                const lowerCasedValue = value ? value.toLowerCase() : '';
                return !spamWords.some(word => lowerCasedValue.includes(word));
            }),
    });

    const handleSubmit = (values) => {
        console.log('Form data', values);
        // Handle form submission and API call here
        setShowModal(false);
    };

    return (
        <>
            {showModal && (
                <div className="modal fade show d-block" tabIndex="-1" role="dialog" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">New Comment</h5>
                                <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowModal(false)}></button>
                            </div>
                            <div className="modal-body">
                                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
                                    {({ isSubmitting }) => (
                                        <Form>
                                            <fieldset>
                                                <div className="row">
                                                    <div className="col-sm-3 col-lg-2 d-none d-sm-block">
                                                        <img className="img-fluid" src="https://bootdey.com/img/Content/avatar/avatar1.png" alt="Avatar" />
                                                    </div>
                                                    <div className="form-group col-12 col-sm-9 col-lg-10">
                                                        <Field as="textarea" className="form-control" id="message" name="message" placeholder="Your message" />
                                                        <ErrorMessage name="message" component="div" className="text-danger" />
                                                    </div>
                                                </div>
                                            </fieldset>
                                            <div className="mt-4 text-center">
                                                <button type="submit" className="btn btn-primary mx-2" disabled={isSubmitting}>
                                                    Submit
                                                </button>
                                                <button type="button" className="btn btn-secondary mx-2 text-white" onClick={() => setShowModal(false)}>
                                                    Close
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Comments;
