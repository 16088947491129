import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="container-fluid bg-dark text-white-50 footer pt-5">
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
            <Link to="/" className="d-inline-block mb-3 text-white">
              <h1>
                <span className="text-white">AI</span>
                <span className="text-white">.</span>
                <span className="text-white">Writers </span>
              </h1>
            </Link>
            <p className="mb-0">
              Tempor erat elitr rebum at clita. Diam dolor diam ipsum et tempor
              sit. Aliqu diam amet diam et eos labore. Clita erat ipsum et lorem
              et sit, sed stet no labore lorem sit. Sanctus clita duo justo et
              tempor
            </p>
          </div>
          <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.3s">
            <h5 className="text-white mb-4">Get In Touch</h5>
            <p>
              <i className="fa fa-map-marker-alt me-3" />
              123 Street, New York, USA
            </p>
            <p>
              <i className="fa fa-phone-alt me-3" />
              +012 345 67890
            </p>
            <p>
              <i className="fa fa-envelope me-3" />
              info@example.com
            </p>
            <div className="d-flex pt-2">
              <a
                className="btn btn-outline-light btn-social me-2"
                href="https://twitter.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitter" />
              </a>
              <a
                className="btn btn-outline-light btn-social me-2"
                href="https://www.facebook.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook-f" />
              </a>
              <a
                className="btn btn-outline-light btn-social me-2"
                href="https://www.youtube.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-youtube" />
              </a>
              <a
                className="btn btn-outline-light btn-social me-2"
                href="https://www.instagram.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram" />
              </a>
              <a
                className="btn btn-outline-light btn-social"
                href="https://www.linkedin.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-linkedin-in" />
              </a>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.5s">
            <h5 className="text-white mb-4">Popular Link</h5>
            <Link to="/about-us" className="btn btn-link text-white mb-2">
              About Us
            </Link>
            <Link to="/contact-us" className="btn btn-link text-white mb-2">
              Contact Us
            </Link>
            <Link to="/privacy-policy" className="btn btn-link text-white mb-2">
              Privacy Policy
            </Link>
            <Link
              to="/terms-and-conditions"
              className="btn btn-link text-white mb-2"
            >
              Terms &amp; Condition
            </Link>
            <Link to="/career" className="btn btn-link text-white mb-2">
              Career
            </Link>
          </div>
          <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.7s">
            <h5 className="text-white mb-4">Our Services</h5>
            <Link
              to="/robotic-automation"
              className="btn btn-link text-white mb-2"
            >
              Robotic Automation
            </Link>
            <Link
              to="/machine-learning"
              className="btn btn-link text-white mb-2"
            >
              Machine learning
            </Link>
            <Link
              to="/predictive-analysis"
              className="btn btn-link text-white mb-2"
            >
              Predictive Analysis
            </Link>
            <Link to="/data-science" className="btn btn-link text-white mb-2">
              Data Science
            </Link>
            <Link
              to="/robot-technology"
              className="btn btn-link text-white mb-2"
            >
              Robot Technology
            </Link>
          </div>
        </div>
      </div>
      <div className="container wow fadeIn" data-wow-delay="0.1s">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              ©{" "}
              <a href="#" className="border-bottom text-white">
                AI Writes
              </a>
              , All Right Reserved. Developed By{" "}
              <a href="#" className="border-bottom text-white">
                Bhavin Padhariya
              </a>{" "}
            </div>
            <div className="col-md-6 text-center text-md-end">
              <div className="footer-menu">
                <Link to="/" className="text-white">
                  Home
                </Link>
                <Link to="/cookies" className="text-white">
                  Cookies
                </Link>
                <Link to="/help" className="text-white">
                  Help
                </Link>
                <Link to="/faqs" className="text-white">
                  FAQs
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
