import React, { useEffect, useState } from "react";
import Navbar from "../../Common/Navbar ";
import Footer from "../../Common/Footer";
import HeroSection from "../../Common/HeroSection";
import FeaturedArticles from "../../Common/FeaturedArticles";
import Testimonial from "../../Common/Testimonial";

const Home = () => {
  return (
    <>
      <Navbar />
      <HeroSection />
      <FeaturedArticles />
      <Testimonial />

      <Footer />
    </>
  );
};

export default Home;
