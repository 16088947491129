import React, { useEffect, useState } from 'react'
import SideBar from '../Common/SideBar'
import Navbar from '../Common/Navbar'
import LoadFiles from '../Common/LoadFiles'
import Loader from '../../global/Common/Loader'
import { useNavigate } from 'react-router-dom'
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import API from '../../../API'
import Message from '../../global/Common/Message'

const AddWriter = () => {

    const initialValues = {
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        mobile: '',
        description: '',
        facebookLink: '',
        instLink: '',
        twitter: '',
        image: '',
        country: '',
        state: '',
        city: ''
    };
    const FILE_SIZE = 2 * 1024 * 1024; // 2 MB
    const SUPPORTED_FORMATS = ['image/jpeg', 'image/png', 'image/gif'];

    const validationSchema = Yup.object({
        name: Yup.string()
            .required('Name is required'), // Name must be filled in
        email: Yup.string()
            .email('Invalid email format') // Email must be in a valid format
            .required('Email is required'), // Email must be filled in
        password: Yup.string()
            .required('Password is required'), // Password must be filled in
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), ''], 'Passwords must match') // Confirm password must match the password
            .required('Confirm Password is required'), // Confirm password must be filled in
        mobile: Yup.string()
            .required('Mobile number is required'), // Mobile number must be filled in
        description: Yup.string(), // Description is optional
        facebookLink: Yup.string()
            .url('Invalid URL format for Facebook link'), // Facebook link must be in a valid URL format
        instLink: Yup.string()
            .url('Invalid URL format for Instagram link'), // Instagram link must be in a valid URL format
        twitter: Yup.string()
            .url('Invalid URL format for Twitter link'), // Twitter link must be in a valid URL format
        image: Yup.mixed()
            .required('Image is required') // Image must be uploaded
            .test(
                'fileSize',
                'File size is too large. Maximum size is 2MB', // Image file size must be 2MB or less
                value => !value || (value && value.size <= FILE_SIZE)
            )
            .test(
                'fileFormat',
                'Unsupported file format. Only JPEG, PNG, and GIF are supported', // Image must be in JPEG, PNG, or GIF format
                value => !value || (value && SUPPORTED_FORMATS.includes(value.type))
            ),
        country: Yup.string()
            .required('Country is required'), // Country must be selected
        state: Yup.string()
            .required('State is required'), // State must be selected
        city: Yup.string()
            .required('City is required') // City must be selected
    });



    const onSubmit = (values, { setSubmitting }) => {
        console.log('Form data', values);
        const formData = new FormData();
        for (const key in values) {
            formData.append(key, values[key]);
        }
        API.post("/writer", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((res) => {
                if (res.data.status === "success") {
                    // localStorage.removeItem("adminLogin");
                    setAlertVisible(res); // No unique key here
                    setSubmitting(false);
                    Navigate("/admin/writerList");

                }
            })
            .catch((error) => {
                console.log(error);
                setAlertVisible(error); // No unique key here
                setSubmitting(false);

            });




    };

    const Navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true); // State to track loading
    const [alertVisible, setAlertVisible] = useState(null);

    useEffect(() => {
        // Simulate loading delay (replace with actual logic to check if LoadFiles is loaded)
        setTimeout(() => {
            setIsLoading(false); // Set loading to false after some delay
        }, 1000); // Example: Simulate 2 seconds delay
    }, []);

    const [sidebarToggled, setSidebarToggled] = useState(false);

    const handleToggle = () => {
        setSidebarToggled(!sidebarToggled);
    };
    return (
        <>
            {isLoading ? (
                <Loader />
            ) : <div id="wrapper">
                <SideBar sidebarToggled={sidebarToggled} handleToggle={handleToggle} />
                <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                        <Navbar handleToggle={handleToggle} />
                        <div className="container-fluid">
                            <div className='row'>
                                {alertVisible && (
                                    <Message
                                        alertVisible={alertVisible}
                                        setAlertVisible={setAlertVisible}
                                    />
                                )}
                                <div className='col-md-6  '>
                                    <div className=" mb-4">
                                        <h1 className="h3 mb-0 text-gray-800">Add Writer</h1>
                                    </div>
                                </div>
                                <div className='col-md-6   text-lg-right '>

                                </div>

                            </div>
                        </div>

                        <div className="container">

                            <div className="card o-hidden border-0 shadow-lg    ">
                                <div className="card-body p-0">
                                    {/* Nested Row within Card Body */}
                                    <div className="row">

                                        <div className="col-lg-12">
                                            <div className="p-4">


                                                <Formik
                                                    initialValues={initialValues}
                                                    validationSchema={validationSchema}
                                                    onSubmit={onSubmit}
                                                >
                                                    {({ isSubmitting, setFieldValue }) => (
                                                        <Form className="user">
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="mb-3 mt-3">
                                                                        <label htmlFor="name" className="form-label">Name:</label>
                                                                        <Field type="text" className="form-control" id="name" name="name" />
                                                                        <ErrorMessage name="name" component="div" className="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="mb-3 mt-3">
                                                                        <label htmlFor="email" className="form-label">Email:</label>
                                                                        <Field type="email" className="form-control" id="email" name="email" />
                                                                        <ErrorMessage name="email" component="div" className="text-danger" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="mb-3 mt-3">
                                                                        <label htmlFor="password" className="form-label">Password:</label>
                                                                        <Field type="password" className="form-control" id="password" name="password" />
                                                                        <ErrorMessage name="password" component="div" className="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="mb-3 mt-3">
                                                                        <label htmlFor="confirmPassword" className="form-label">Confirm Password:</label>
                                                                        <Field type="password" className="form-control" id="confirmPassword" name="confirmPassword" />
                                                                        <ErrorMessage name="confirmPassword" component="div" className="text-danger" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="mb-3 mt-3">
                                                                        <label htmlFor="mobile" className="form-label">Mobile:</label>
                                                                        <Field type="text" className="form-control" id="mobile" name="mobile" />
                                                                        <ErrorMessage name="mobile" component="div" className="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="mb-3 mt-3">
                                                                        <label htmlFor="description" className="form-label">Description:</label>
                                                                        <Field type="text" className="form-control" id="description" name="description" />
                                                                        <ErrorMessage name="description" component="div" className="text-danger" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="mb-3 mt-3">
                                                                        <label htmlFor="facebookLink" className="form-label">Facebook Link:</label>
                                                                        <Field type="url" className="form-control" id="facebookLink" name="facebookLink" />
                                                                        <ErrorMessage name="facebookLink" component="div" className="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="mb-3 mt-3">
                                                                        <label htmlFor="instLink" className="form-label">Instagram Link:</label>
                                                                        <Field type="url" className="form-control" id="instLink" name="instLink" />
                                                                        <ErrorMessage name="instLink" component="div" className="text-danger" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="mb-3 mt-3">
                                                                        <label htmlFor="twitter" className="form-label">Twitter Link:</label>
                                                                        <Field type="url" className="form-control" id="twitter" name="twitter" />
                                                                        <ErrorMessage name="twitter" component="div" className="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="mb-3 mt-3">
                                                                        <label htmlFor="image" className="form-label">Image:</label>
                                                                        <input
                                                                            type="file"
                                                                            className="form-control"
                                                                            id="image"
                                                                            name="image"
                                                                            onChange={(event) => {
                                                                                setFieldValue('image', event.currentTarget.files[0]);
                                                                            }}
                                                                        />
                                                                        <ErrorMessage name="image" component="div" className="text-danger" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="mb-3 mt-3">
                                                                        <label htmlFor="country" className="form-label">Country:</label>
                                                                        <Field type="text" className="form-control" id="country" name="country" />
                                                                        <ErrorMessage name="country" component="div" className="text-danger" />
                                                                    </div>
                                                                </div>
                                                                <div className="col">
                                                                    <div className="mb-3 mt-3">
                                                                        <label htmlFor="state" className="form-label">State:</label>
                                                                        <Field type="text" className="form-control" id="state" name="state" />
                                                                        <ErrorMessage name="state" component="div" className="text-danger" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col">
                                                                    <div className="mb-3 mt-3">
                                                                        <label htmlFor="city" className="form-label">City:</label>
                                                                        <Field type="text" className="form-control" id="city" name="city" />
                                                                        <ErrorMessage name="city" component="div" className="text-danger" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='d-sm-flex align-items-center justify-content-center'>
                                                                <button type="submit" className="btn btn-primary" disabled={isSubmitting} > {isSubmitting ? (
                                                                    <div class="spinner-border spinner-border-sm"></div>
                                                                ) : (
                                                                    "Submit"
                                                                )}</button>


                                                                <button type="button" class="btn btn-danger ml-3" onClick={() => {
                                                                    Navigate("/admin/writerList")
                                                                }}>back</button>
                                                            </div>
                                                        </Form>
                                                    )}
                                                </Formik>
                                            </div>
                                        </div>



                                    </div>

                                </div>

                            </div>
                            <br />
                        </div>






                    </div>
                </div>
            </div>}




            <LoadFiles />
        </>
    )
}

export default AddWriter