import React, { useEffect } from "react";

const DashboardData = () => {
  return (
    <>
      {/* <div className="container-fluid"> */}
      {/* Page Heading */}
      <div className="d-sm-flex align-items-center justify-content-center mb-4">

        <h1 className="h3 mb-0 text-gray-800">Welcome Admin!</h1>
      </div>

      {/* Content Row */}

      {/* Content Row */}
      {/* </div> */}
    </>
  );
};

export default DashboardData;
