import React, { useEffect, useState } from "react";
import Loader from "../../Common/Loader/Loader";
import BodyColorLoad from "../../Common/BodyColorLoad";
import API from "../../../../API";
import Message from "../../Common/Message";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup"; // Import Yup for validation
import { en } from "../../../../utils/Helper";
import { useNavigate, Link } from "react-router-dom";
import LoadFiles from "../../../Admin/Common/LoadFiles";

const Registration = () => {
  const Navigate = useNavigate();
  const initialValues = {
    name: "",
    email: "",
    password: "",
    repeatPassword: "",
    mobile: "",
    iWantToBe: "",
    rememberMe: false,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email format").required("Required"),
    password: Yup.string().required("Required"),
    mobile: Yup.string()
      .min(10, "mobile number should be 10 digit")
      .required("mobile is required"),
    repeatPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Required"),
    iWantToBe: Yup.string().required("Required"),
    rememberMe: Yup.boolean(),
  });

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    // Simulate an async login API call
    setTimeout(() => {
      //console.log("Form data", values);
      // alert(JSON.stringify(values, null, 2));
      formFinalSubmit(values, setSubmitting);
    }, 1000);
  };
  const formFinalSubmit = (values, setSubmitting) => {
    API.post("/register", {
      name: en(values.name),
      email: en(values.email),
      password: en(values.password),
      repeatPassword: en(values.repeatPassword),
      mobile: en(values.mobile),
      iWantToBe: en(values.iWantToBe),
      rememberMe: en(values.rememberMe),
    })
      .then((res) => {
        if (res.data.status === "success") {
          console.log(res);
          setAlertVisible(res); // No unique key here
          setSubmitting(false);
          setStepHideAndShow(true); //this is for the otp box show
        }
      })
      .catch((error) => {
        console.log(error);
        setAlertVisible(error); // No unique key here
        setSubmitting(false);
      });
  };

  const initialValues2 = {
    otp: "",
  };

  const validationSchema2 = Yup.object().shape({
    otp: Yup.string().required("Required"),
  });

  const [stepHideAndShow, setStepHideAndShow] = useState(false);
  const [alertVisible, setAlertVisible] = useState(null);

  const onSubmitOTP = (values, { setSubmitting }) => {
    setSubmitting(true);
    // Simulate an async login API call
    setTimeout(() => {
      //console.log("Form data", values);
      // alert(JSON.stringify(values, null, 2));
      verifyOtp(values, setSubmitting);
    }, 1000);
  };

  const verifyOtp = (values, setSubmitting) => {
    API.post("/verifyOtp", {
      otp: en(values.otp),
    })
      .then((res) => {
        if (res.data.status === "success") {
          console.log(res);
          setAlertVisible(res); // No unique key here
          setSubmitting(false);
          Navigate("/reader/blogs");
        }
      })
      .catch((error) => {
        console.log(error);
        setAlertVisible(error); // No unique key here
        setSubmitting(false);
      });
  };
  return (
    <>
      <div
        className="container-fluid bg-gradient-primary"
        style={{ height: "100vh" }}
      >
        {/* Outer Row */}
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-12 col-md-9">
            {alertVisible && (
              <Message
                alertVisible={alertVisible}
                setAlertVisible={setAlertVisible}
              />
            )}

            <div className="card o-hidden border-0 shadow-lg my-5 mt-5">
              <div className="card-body p-0">
                {/* Nested Row within Card Body */}
                <div className="row">
                  <div className="col-lg-6 d-none d-lg-block bg-login-image" />
                  <div className="col-lg-6">
                    <div className="p-5">
                      <div className="text-center">
                        <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                      </div>
                      {stepHideAndShow == false ? (
                        <Formik
                          initialValues={initialValues}
                          validationSchema={validationSchema}
                          onSubmit={onSubmit}
                        >
                          {({ isSubmitting }) => (
                            <Form className="user">
                              <div className="form-group">
                                <Field
                                  type="text"
                                  name="name"
                                  className="form-control form-control-user"
                                  id="name"
                                  placeholder="Enter your name..."
                                />
                                <ErrorMessage
                                  name="name"
                                  component="div"
                                  className="text-danger pl-3"
                                />
                              </div>
                              <div className="form-group">
                                <Field
                                  type="email"
                                  name="email"
                                  className="form-control form-control-user"
                                  id="email"
                                  aria-describedby="emailHelp"
                                  placeholder="Enter Email Address..."
                                />
                                <ErrorMessage
                                  name="email"
                                  component="div"
                                  className="text-danger pl-3"
                                />
                              </div>
                              <div className="form-group">
                                <Field
                                  type="password"
                                  name="password"
                                  className="form-control form-control-user"
                                  id="password"
                                  placeholder="Password"
                                />
                                <ErrorMessage
                                  name="password"
                                  component="div"
                                  className="text-danger pl-3"
                                />
                              </div>
                              <div className="form-group">
                                <Field
                                  type="password"
                                  name="repeatPassword"
                                  className="form-control form-control-user"
                                  id="repeatPassword"
                                  placeholder="Repeat Password"
                                />
                                <ErrorMessage
                                  name="repeatPassword"
                                  component="div"
                                  className="text-danger pl-3"
                                />
                              </div>
                              <div className="form-group">
                                <Field
                                  type="mobile"
                                  name="mobile"
                                  className="form-control form-control-user"
                                  id="mobile"
                                  placeholder="mobile"
                                />
                                <ErrorMessage
                                  name="mobile"
                                  component="div"
                                  className="text-danger pl-3"
                                />
                              </div>
                              <div className="form-group">
                                <label className="form-check-label pr-2">
                                  I Want To Be:
                                </label>
                                <div className="form-check-inline">
                                  <Field
                                    type="radio"
                                    name="iWantToBe"
                                    value="reader"
                                    className="form-check-input"
                                    id="iWantToBeReader"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="iWantToBeReader"
                                  >
                                    Reader
                                  </label>
                                </div>
                                <div className="form-check-inline">
                                  <Field
                                    type="radio"
                                    name="iWantToBe"
                                    value="writer"
                                    className="form-check-input"
                                    id="iWantToBeWriter"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="iWantToBeWriter"
                                  >
                                    Writer
                                  </label>
                                </div>
                                <ErrorMessage
                                  name="iWantToBe"
                                  component="div"
                                  className="text-danger pl-3"
                                />
                              </div>
                              <div className="form-group">
                                <div className="custom-control custom-checkbox small">
                                  <Field
                                    type="checkbox"
                                    name="rememberMe"
                                    className="custom-control-input"
                                    id="customCheck"
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customCheck"
                                  >
                                    Remember Me
                                  </label>
                                </div>
                              </div>
                              <button
                                type="submit"
                                className="btn bg_custom_primary btn-user btn-block"
                                disabled={isSubmitting}
                              >
                                {isSubmitting ? "Submitting..." : "Submit"}
                              </button>
                            </Form>
                          )}
                        </Formik>
                      ) : (
                        <Formik
                          initialValues={initialValues2}
                          validationSchema={validationSchema2}
                          onSubmit={onSubmitOTP}
                        >
                          {({ isSubmitting }) => (
                            <Form className="user">
                              <div className="form-group">
                                <Field
                                  type="text"
                                  name="otp"
                                  className="form-control form-control-user"
                                  id="exampleInputOTP"
                                  placeholder="Enter OTP..."
                                />
                                <ErrorMessage
                                  name="otp"
                                  component="div"
                                  className="text-danger pl-3"
                                />
                              </div>
                              <button
                                type="submit"
                                className="btn bg_custom_primary btn-user btn-block"
                                disabled={isSubmitting}
                              >
                                {isSubmitting ? (
                                  <div className="spinner-border spinner-border-sm"></div>
                                ) : (
                                  "Submit OTP"
                                )}
                              </button>
                            </Form>
                          )}
                        </Formik>
                      )}

                      <hr />
                      <div className="text-center">
                        <a className="small" href="forgot-password.html">
                          Forgot Password?
                        </a>
                      </div>
                      <div className="text-center">
                        <Link className="small" to="/registration">
                          Create an Account!
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoadFiles />
    </>
  );
};

export default Registration;
