import React, { useEffect, useState } from 'react';
import SideBar from '../Common/SideBar';
import Navbar from '../Common/Navbar';
import LoadFiles from '../Common/LoadFiles';
import Loader from '../../global/Common/Loader';
import { useNavigate } from 'react-router-dom';
import API from '../../../API';
import Message from '../../global/Common/Message';
import { en2, shortDesc } from '../../../utils/Helper';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';


const ArticlesList = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [articles, setArticles] = useState([]);
    const [alertVisible, setAlertVisible] = useState(null);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }, []);

    const [sidebarToggled, setSidebarToggled] = useState(false);

    const handleToggle = () => {
        setSidebarToggled(!sidebarToggled);
    };

    useEffect(() => {
        articlesList();
    }, [first, rows]);

    const articlesList = async () => {
        try {
            const response = await API.get('/articles');
            if (response.data && Array.isArray(response.data)) {
                setArticles(response.data);
            } else {
                console.error('Invalid response structure:', response.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleEdit = (article) => {
        navigate('/admin/updateArticle', { state: { data: article } });
    };

    const handleDelete = (id) => {
        if (window.confirm('Are you sure you want to delete this data?')) {
            API.delete(`/articles/${id}`)
                .then((res) => {
                    if (res.data.status === 'success') {
                        setArticles((prevArticles) => prevArticles.filter((article) => article.id !== id));
                        setAlertVisible(res);
                    }
                })
                .catch((error) => {
                    setAlertVisible(error);
                });
        }
    };

    const handleToggleStatus = (id, currentStatus) => {
        // if (currentStatus === 1) {
        //     alert('The article has been published and cannot be disabled.');
        //     return;
        // }

        const newStatus = currentStatus === 0 ? 1 : 0;
        setArticles((prevArticles) =>
            prevArticles.map((article) =>
                article.id === id ? { ...article, status: newStatus } : article
            )
        );

        API.put(`/articles/status/${id}`, {})
            .then((res) => {
                if (res.data.status === 'success') {
                    setAlertVisible(res);
                    articlesList();
                }
            })
            .catch((error) => {
                setAlertVisible(error);
            });
    };

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <div id="wrapper">
                    <SideBar sidebarToggled={sidebarToggled} handleToggle={handleToggle} />
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            <Navbar handleToggle={handleToggle} />

                            {alertVisible && (
                                <Message
                                    alertVisible={alertVisible}
                                    setAlertVisible={setAlertVisible}
                                />
                            )}

                            <div className="container-fluid">
                                <div className="card shadow mb-4">
                                    <div className="card-header py-3">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h6 className="m-3 font-weight-bold text-primary">Articles List</h6>
                                            </div>
                                            <div className="col-md-6 text-lg-right">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary mt-2"
                                                    onClick={() => {
                                                        navigate('/admin/addArticles');
                                                    }}
                                                >
                                                    Add Articles
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <DataTable value={articles} paginator={true} rows={rows} first={first} onPage={onPageChange}>
                                                <Column field="id" header="SRNO." body={(rowData, { rowIndex }) => rowIndex + 1 + first} style={{ width: '50px' }}></Column>
                                                <Column field="title" header="Title" style={{ width: '200px' }}></Column>
                                                <Column field="content" header="Content" body={(rowData) => shortDesc(rowData.content, 2)} style={{ width: '300px' }}></Column>
                                                <Column field="writer.name" header="Writer" style={{ width: '150px' }}></Column>
                                                <Column field="category.name" header="Category" style={{ width: '150px' }}></Column>
                                                <Column field="tags" header="Tags" style={{ width: '200px' }}></Column>
                                                <Column field="published_at" header="Published At" style={{ width: '150px' }}></Column>
                                                <Column field="status" header="Status" body={(rowData) => (
                                                    <div className="form-check form-switch ml-3">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            role="switch"
                                                            checked={rowData.status === 1}
                                                            onChange={() => handleToggleStatus(rowData.id, rowData.status)}
                                                        />
                                                    </div>
                                                )} style={{ width: '100px' }}></Column>
                                                <Column header="Action" body={(rowData) => (
                                                    <>
                                                        <button className="btn btn-warning me-2 mb-2" onClick={() => handleEdit(rowData)}>
                                                            <i className="fas fa-edit" />
                                                        </button>
                                                        <button className="btn btn-danger me-2 mb-2" onClick={() => handleDelete(rowData.id)}>
                                                            <i className="fas fa-trash" />
                                                        </button>
                                                        <a href={`/writer/${en2(rowData.id)}/blog`} target="__blank" className="btn btn-info me-2 mb-2">
                                                            <i className="fas fa-eye" />
                                                        </a>
                                                    </>
                                                )} style={{ width: '250px' }} bodyStyle={{ textAlign: 'center' }}></Column>
                                            </DataTable>
                                            {/* <Paginator first={first} rows={rows} totalRecords={articles.length} onPageChange={onPageChange}/> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <LoadFiles />
        </>
    );
};

export default ArticlesList;
