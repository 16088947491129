import CryptoJS from "crypto-js";
import aes from "crypto-js/aes";
import encHex from "crypto-js/enc-hex";
import padZeroPadding from "crypto-js/pad-zeropadding";

export function en(data) {
  const apiTest = process.env.REACT_APP_API_TEST;
  if (apiTest == 1) {
    return "" + data + "";
  }

  let uuid = get_hex(32);
  try {
    let text =
      data !== undefined && data !== null && data !== "" ? "" + data + "" : "";
    if (text === "") {
      return text;
    }

    let key = encHex.parse(process.env.REACT_APP_EN_ID1);
    let iv = "";
    if (
      uuid === "undefined" ||
      uuid === "null" ||
      uuid === undefined ||
      uuid === null ||
      uuid === ""
    ) {
      iv = encHex.parse(process.env.REACT_APP_EN_ID2);
    } else {
      iv = encHex.parse(uuid);
    }
    let en1 = aes
      .encrypt(text, key, { iv: iv, padding: padZeroPadding })
      .toString();

    return encodeURIComponent(window.btoa(en1 + "" + uuid));
  } catch (e) {
    return "";
  }
}

export function de(data) {
  const apiTest = process.env.REACT_APP_API_TEST;
  if (apiTest == 1) {
    return "" + data + "";
  }

  data = window.atob(decodeURIComponent(data));

  let uuid = data.slice(-32);
  data = data.substring(0, data.indexOf(uuid));
  try {
    let encrypted =
      data !== undefined && data !== null && data !== "" ? data : "";
    if (encrypted === "") {
      return encrypted;
    }

    let key = encHex.parse(process.env.REACT_APP_EN_ID1);
    let iv = "";
    if (
      uuid === "undefined" ||
      uuid === "null" ||
      uuid === undefined ||
      uuid === null ||
      uuid === ""
    ) {
      iv = encHex.parse(process.env.REACT_APP_EN_ID2);
    } else {
      iv = encHex.parse(uuid);
    }
    let de1 = aes
      .decrypt(encrypted, key, { iv: iv })
      .toString(CryptoJS.enc.Utf8);

    return de1;
  } catch (e) {
    return "";
  }
}


export function en2(data) {


  let uuid = get_hex(32);
  try {
    let text =
      data !== undefined && data !== null && data !== "" ? "" + data + "" : "";
    if (text === "") {
      return text;
    }

    let key = encHex.parse(process.env.REACT_APP_EN_ID1);
    let iv = "";
    if (
      uuid === "undefined" ||
      uuid === "null" ||
      uuid === undefined ||
      uuid === null ||
      uuid === ""
    ) {
      iv = encHex.parse(process.env.REACT_APP_EN_ID2);
    } else {
      iv = encHex.parse(uuid);
    }
    let en1 = aes
      .encrypt(text, key, { iv: iv, padding: padZeroPadding })
      .toString();

    return encodeURIComponent(window.btoa(en1 + "" + uuid));
  } catch (e) {
    return "";
  }
}

export function de2(data) {

  data = window.atob(decodeURIComponent(data));

  let uuid = data.slice(-32);
  data = data.substring(0, data.indexOf(uuid));
  try {
    let encrypted =
      data !== undefined && data !== null && data !== "" ? data : "";
    if (encrypted === "") {
      return encrypted;
    }

    let key = encHex.parse(process.env.REACT_APP_EN_ID1);
    let iv = "";
    if (
      uuid === "undefined" ||
      uuid === "null" ||
      uuid === undefined ||
      uuid === null ||
      uuid === ""
    ) {
      iv = encHex.parse(process.env.REACT_APP_EN_ID2);
    } else {
      iv = encHex.parse(uuid);
    }
    let de1 = aes
      .decrypt(encrypted, key, { iv: iv })
      .toString(CryptoJS.enc.Utf8);

    return de1;
  } catch (e) {
    return "";
  }
}



export function get_hex(len) {
  const hex = "0123456789ABCDEF";
  let output = "";
  for (let i = 0; i < len; ++i) {
    output += hex.charAt(Math.floor(Math.random() * hex.length));
  }
  return output;
}


export function shortDesc(text, wordLimit) {
  const words = text.split(' ');
  if (words.length <= wordLimit) {
      return text;
  }
  return words.slice(0, wordLimit).join(' ') + '...';
}



