import React, { useState } from "react";
import { Link } from "react-router-dom";
import API from "../../../API";
import { useNavigate } from "react-router-dom";
import Message from "../../global/Common/Message";

const Navbar = ({ handleToggle }) => {
  const Navigate = useNavigate();
  const [alertVisible, setAlertVisible] = useState(null);
  const [sidebarToggled, setSidebarToggled] = useState(false);

  // Determine the class based on the sidebarToggled state
  const navClass = `navbar navbar-expand navbar-light custom_primary_color topbar mb-4 static-top shadow ${sidebarToggled
      ? "bg-gradient-primary sidebar sidebar-dark accordion toggled"
      : ""
    }`;

  const logout = () => {
    API.post("/logout", {})
      .then((res) => {
        if (res.data.status === "success") {
          localStorage.removeItem("token");
          Navigate("/login");

          setAlertVisible(res); // No unique key here
        }
      })
      .catch((error) => {
        console.log(error);
        setAlertVisible(error); // No unique key here
      });
  };

  return (
    <>
      <nav className={navClass}>
        {/* Sidebar Toggle (Topbar) */}
        <button
          id="sidebarToggleTop"
          className="btn btn-link d-md-none rounded-circle mr-3"
          onClick={handleToggle}
        >
          <i className="fa fa-bars" />
        </button>
        {alertVisible && (
          <Message
            alertVisible={alertVisible}
            setAlertVisible={setAlertVisible}
          />
        )}

        {/* Topbar Navbar */}
        <ul className="navbar-nav ml-auto">
          {/* Nav Item - Search Dropdown (Visible Only XS) */}
          <li className="nav-item dropdown no-arrow d-sm-none">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="searchDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fas fa-search fa-fw" />
            </a>
            {/* Dropdown - Messages */}
            <div
              className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
              aria-labelledby="searchDropdown"
            >
              <form className="form-inline mr-auto w-100 navbar-search">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control bg-light border-0 small"
                    placeholder="Search for..."
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                  />
                  <div className="input-group-append">
                    <button className="btn btn-primary" type="button">
                      <i className="fas fa-search fa-sm" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </li>

          <div className="topbar-divider d-none d-sm-block" />
          {/* Nav Item - User Information */}
          <li className="nav-item dropdown no-arrow">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="userDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img
                className="img-profile rounded-circle"
                src="/assets/img/logo.png"
              />
              <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                Admin
              </span>
            </a>
            {/* Dropdown - User Information */}
            <div
              className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
              aria-labelledby="userDropdown"
            >
              <a className="dropdown-item" href="#">
                <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400" />
                Profile
              </a>
              <div className="dropdown-divider" />
              <div
                className="dropdown-item"
                // to="/login"
                data-toggle="modal"
                data-target="#logoutModal"
                onClick={() => {
                  Navigate("/login");
                  localStorage.removeItem("token");
                }}
              >
                <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
                Logout
              </div>
            </div>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
