import React, { useEffect, useState } from "react";

const Message = ({ alertVisible, setAlertVisible }) => {
  console.log(
    "Alert status:",
    alertVisible?.response?.data?.status === "failure"
  );

  const handleClose = () => {
    setAlertVisible(null);
  };
  return (
    <>
      {alertVisible?.data?.status === "success"   ? (
        <div className="floating-alert alert alert-info alert-dismissible">
          <button
            type="button"
            className="btn-close"
            onClick={handleClose}
          ></button>
          <strong>success!</strong> {alertVisible?.data?.message}
        </div>
      ) : (
        (alertVisible?.response?.data?.status === "failure" ||
          alertVisible?.response?.data?.exception !== "") && (
          <div className="floating-alert alert alert-danger alert-dismissible">
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
            ></button>
            <strong>failure!</strong> {alertVisible?.response?.data?.message}
          </div>
        )
      )}
    </>
  );
};

export default Message;
