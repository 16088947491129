import React from "react";

const HeroSection = () => {
  return (
    <>
      {" "}
      <div className="container-fluid pt-5 custom_primary_color hero-header">
        <div className="container pt-5">
          <div className="row g-5 pt-5">
            <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">
              <div className="btn btn-sm border rounded-pill text-white px-3 mb-3 animated slideInRight">
                AI.Tech
              </div>
              <h1 className="display-4 text-white mb-4 animated slideInRight">
                Artificial Intelligence for Your Business
              </h1>
              <p className="text-white mb-4 animated slideInRight">
                Tempor rebum no at dolore lorem clita rebum rebum ipsum rebum
                stet dolor sed justo kasd. Ut dolor sed magna dolor sea diam.
                Sit diam sit
              </p>
              <a
                href="#"
                className="btn btn-light py-sm-3 px-sm-5 rounded-pill me-3 animated slideInRight fw-bold"
              >
                Explore Blogs
              </a>
              <a
                href="#"
                className="btn btn-outline-light py-sm-3 px-sm-5 rounded-pill animated slideInRight fw-bold"
              >
                Login
              </a>
            </div>
            <div className="col-lg-6 align-self-end text-center text-lg-end">
              <img
                className="img-fluid"
                src="/assets/img/hero-img.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
