import axios from 'axios';

const serverPath = process.env.REACT_APP_SERVER_PROJPATH;

// Create an instance of axios
const API = axios.create({
  baseURL: serverPath,
  withCredentials: true,
  headers: {
    'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
    'Content-Security-Policy': "frame-ancestors 'none';",
    'X-Frame-Options': 'DENY',
    'X-Content-Type-Options': 'nosniff',
    'Server': '',
    'X-XSS-Protection': '1; mode=block',
    'Referrer-Policy': 'no-referrer-when-downgrade',
    'Content-Type': 'application/x-www-form-urlencoded',
  }
});

// Add a request interceptor
API.interceptors.request.use(
  config => {
    const tokenData = JSON.parse(localStorage.getItem('token'));
    const token = tokenData && tokenData.data ? tokenData.data.access_token : null;
    const expiresAt = tokenData && tokenData.data ? tokenData.data.expires_at : null;

    if (token && expiresAt) {
      const currentTime = Math.floor(Date.now() / 1000);
      if (currentTime >= expiresAt) {
        console.error("Token expired, redirecting to login");
        localStorage.removeItem('token'); // Clear expired token
        window.location.href = '/login'; // Redirect to login
      } else {
        config.headers.Authorization = `Bearer ${token}`;
      }
    } else {
      console.error("No token found");
      //window.location.href = '/login'; // Redirect to login
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
API.interceptors.response.use(
  response => response,
  error => {
    // Check if the error response indicates an expired token
    if (error.response && error.response.status === 401) {
      console.error("Token expired or unauthorized, redirecting to login");
      localStorage.removeItem('token'); // Clear invalid token
      //window.location.href = '/login'; // Redirect to login
    }
    return Promise.reject(error);
  }
);

export default API;
